import React from 'react';
import { useSelector } from 'react-redux';
import { Container, makeStyles, Typography } from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PublicLayout from '../components/PublicLayout';
import { RootState } from '../../store';
import PaymentForm from '../forms/PaymentForm';

const useStyles = makeStyles(() => ({
  container: {
    paddingBottom: '3rem',
    paddingTop: '3rem',
  },
}));

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = stripePublicKey ? loadStripe(stripePublicKey) : Promise.resolve(null);

const Payment = (): React.ReactElement => {
  const classes = useStyles();
  const name = useSelector<RootState>(state => state.team.name);

  return (
    <PublicLayout>
      <Container maxWidth='md' className={classes.container}>
        <Typography variant='h1' gutterBottom>Équipe {name}</Typography>
        <Typography paragraph variant='subtitle1' color='textSecondary'>
          Les frais d&apos;inscription, modiques (26€ par équipe, quelque soit le nombre de participants), servent essentiellement à rembourser les frais informatiques et à acheter des lots pour récompenser toutes les équipes lors d&apos;un grand dîner de clôture en avril.
        </Typography>
        <Container maxWidth='sm' className={classes.container}>
          {stripePromise ? (
            <Elements stripe={stripePromise}>
              <PaymentForm />
            </Elements>
          ) : <></>}
          <Typography variant='caption'>*Powered by Stripe</Typography>
        </Container>
        <Typography align='center' variant='body2' color='textSecondary'>
          {`Vous rencontrez un problème ? Contactez-nous à l'adresse `}
          <a href="mailto:rallye.hiver.2021@gmail.com">rallye.hiver.2021@gmail.com</a>
        </Typography>
      </Container>
    </PublicLayout >
  );
};

export default Payment;
