import React from 'react';
import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { FetchTeamStatsResponse } from '../../services/team';
import 'moment/locale/fr';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    textAlign: 'center',
    padding: `${theme.spacing(4)}px 0`,
  },
}));

type StatisticsPropsType = {
  loading: boolean;
  stats?: FetchTeamStatsResponse;
}

const Statistics = ({ loading, stats }: StatisticsPropsType): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        <Typography variant='subtitle2'>Énigmes résolues</Typography>
        {loading && <CircularProgress color='inherit' />}
        {!loading && <Typography variant='h2'>{stats ? Object.keys(stats.solvedRiddles || {}).length : '0'}</Typography>}
      </div>
      <div>
        <Typography variant='subtitle2'>Parcours effectués</Typography>
        {loading && <CircularProgress color='inherit' />}
        {!loading && <Typography variant='h2'>{stats ? Object.keys(stats.questsDone || {}).length : '0'}</Typography>}
      </div>
      <div>
        <Typography variant='subtitle2'>Mots de passe essayés</Typography>
        {loading && <CircularProgress color='inherit' />}
        {!loading && <Typography variant='h2'>{stats ? stats.answersSubmissionCount : '0'}</Typography>}
      </div>
    </div>
  );
};

export default Statistics;
