import React, { ReactElement } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import PrintIcon from '@material-ui/icons/Print';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import TheatersIcon from '@material-ui/icons/Theaters';

interface DownloadFileProps {
  url: string;
  title: string;
  icon?: string;
}

const DownloadFile = ({ url, title, icon = 'download' }: DownloadFileProps): ReactElement => (
  <a target='_blank' rel='noopener noreferrer' href={url}>
    <Tooltip title={title} placement='top'>
      <IconButton aria-label={title} component='span'>
        {icon === 'download' && <GetAppIcon />}
        {icon === 'print' && <PrintIcon />}
        {icon === 'audio' && <MusicNoteIcon />}
        {icon === 'video' && <TheatersIcon />}
      </IconButton>
    </Tooltip>
  </a>
);

export default DownloadFile;
