export class LoginDoesNotExistError extends Error {}
export class InvalidPasswordError extends Error {}
export class UnknownError extends Error {}

export const parseOrThrowError = async <T>(response: Response): Promise<T> => {
  const parsedResponse = await response.json();
  if (!response.ok) {
    if (response.status === 404) throw new LoginDoesNotExistError(parsedResponse.userFacingError);
    if (response.status === 403) throw new InvalidPasswordError(parsedResponse.userFacingError);
    throw new UnknownError(parsedResponse.userFacingError);
  }
  return parsedResponse as unknown as T;
};
