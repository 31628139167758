import React from 'react';
import { Container, makeStyles, Typography } from '@material-ui/core';
import PublicLayout from '../components/PublicLayout';
import RegisterForm from '../forms/RegisterForm';

const useStyles = makeStyles(() => ({
  container: {
    paddingBottom: '3rem',
    paddingTop: '3rem',
  },
}));

const Register = (): React.ReactElement => {
  const classes = useStyles();
  return (
    <PublicLayout>
      <Container maxWidth='md' className={classes.container}>
        <Typography variant='h1' gutterBottom>Inscription pour l&apos;édition 2020/2021 : "Les Méchants"</Typography>
        <Typography paragraph variant='subtitle1'>
          Pour participer, formez une équipe (habituellement de 4 à 8 personnes) avant le début de l&apos;hiver, donnez-lui un nom et désignez un chef d&apos;équipe. Il suffit alors au chef d’équipe de renseigner le formulaire ci-dessous pour finaliser votre inscription.
        </Typography>
        <Typography paragraph variant='subtitle1'>
          Les frais d&apos;inscription (26€ par équipe, quel que soit le nombre de participants), servent à rembourser les frais d&apos;organisation (l&apos;équipe organisatrice restant bénévole) et à acheter des lots de récompenses pour les équipes à l&apos;issue des résultats !
        </Typography>
        <Typography paragraph variant='subtitle1'>
          Le règlement s’effectue par CB en 2e étape du formulaire ci-dessous.
        </Typography>
        <Container maxWidth='sm' className={classes.container}>
          <RegisterForm />
        </Container>
      </Container>
    </PublicLayout >
  );
};

export default Register;
