import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import Box from '@material-ui/core/Box';
import PublicLayout from '../components/PublicLayout';
// import AdminTable from '../components/AdminTable';
// import { fetchTeams, fetchEvents, fetchErrors } from '../../services/admin';
// import { AdminTeam, AdminTableAnswerSubmission, AdminTableTeam } from '../../types/team';
// import { Events, AdminTableEvents } from '../../types/events';
// import { Errors, AdminTableErrors } from '../../types/errors';
import AdminExtracts from '../components/AdminExtracts';

// type TabPanelProps = {
//   children: React.ReactNode;
//   value: number;
//   index: number;
// }

// interface HeadCell<T> {
//   id: keyof T;
//   label: string;
// }

// const TabPanel = ({ children, value, index }: TabPanelProps): React.ReactElement => (
//   <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`}>
//     {value === index && <Box p={3}>{children}</Box>}
//   </div>
// );

const useStyles = makeStyles(() => ({
  slackContainer: {
    height: '48px',
    width: '48px',
    padding: '9px 12px',
  },
}));

const AdminHome = (): React.ReactElement => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  // const [teams, setTeams] = useState<AdminTeam[]>();
  // const [answersSubmissions, setAnswersSubmissions] = useState<AdminTableAnswerSubmission[]>();
  // const [events, setEvents] = useState<Events[]>();
  // const [errors, setErrors] = useState<Errors[]>();

  // const getTeams = useCallback(async () => {
  //   const fetchedTeams = await fetchTeams();
  //   const fetchedAnswers = fetchedTeams.map(({ answersSubmissions, login }) => (
  //     (answersSubmissions || []).map(answer => ({ ...answer, login }))
  //   )).flat();
  //   setTeams(fetchedTeams);
  //   setAnswersSubmissions(fetchedAnswers);
  // }, []);

  // const getEvents = useCallback(async () => {
  //   const fetchedEvents = await fetchEvents();
  //   setEvents(fetchedEvents);
  // }, []);

  // const getErrors = useCallback(async () => {
  //   const fetchedErrors = await fetchErrors();
  //   setErrors(fetchedErrors);
  // }, []);

  // useEffect(() => {
  //   getTeams();
  //   getEvents();
  //   getErrors();
  // }, [getTeams, getEvents, getErrors]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
    setValue(newValue);
  };

  // const teamHeadCells: HeadCell<AdminTableTeam>[] = [
  //   { id: 'creationDate', label: 'Date de création' },
  //   { id: 'name', label: 'Nom' },
  //   { id: 'login', label: 'Login' },
  //   { id: 'hasPaid', label: 'Payé' },
  //   { id: 'firstname', label: 'Prénom' },
  //   { id: 'lastname', label: 'Nom' },
  //   { id: 'email', label: 'Email' },
  //   { id: 'solvedRiddlesCount', label: 'Nombre d\'énigmes résolues' },
  // ];

  // const answerSubmissionsHeadCells: HeadCell<AdminTableAnswerSubmission>[] = [
  //   { id: 'date', label: 'Date' },
  //   { id: 'login', label: 'Login' },
  //   { id: 'answer', label: 'Réponse' },
  //   { id: 'riddleId', label: 'Énigme' },
  // ];

  // const eventsHeadCells: HeadCell<AdminTableEvents>[] = [
  //   { id: 'date', label: 'Date' },
  //   { id: 'location', label: 'Endpoint' },
  //   { id: 'type', label: 'Type' },
  //   { id: 'login', label: 'Login' },
  //   { id: 'values', label: 'Informations supplémentaires' },
  // ];

  // const errorsHeadCells: HeadCell<AdminTableErrors>[] = [
  //   { id: 'date', label: 'Date' },
  //   { id: 'location', label: 'Endpoint' },
  //   { id: 'type', label: 'Type' },
  //   { id: 'login', label: 'Login' },
  //   { id: 'message', label: 'Message' },
  //   { id: 'values', label: 'Informations supplémentaires' },
  // ];

  // const tableTeams = (teams || []).map(({ solvedRiddles, ...other }) => ({
  //   ...other,
  //   solvedRiddlesCount: Object.keys(solvedRiddles || {}).length,
  // })) as AdminTableTeam[];

  // const tableEvents = (events || []).map(({ values, ...other }) => ({
  //   ...other,
  //   values: Object.entries(values || {}).map(([key, value]) => `${key}: ${value}`).join(', '),
  // })) as AdminTableEvents[];

  // const tableErrors = (errors || []).map(({ values, ...other }) => ({
  //   ...other,
  //   values: Object.entries(values || {}).map(([key, value]) => `${key}: ${value}`).join(', '),
  // })) as AdminTableErrors[];

  const tabs = [
    // {
    //   index: 0,
    //   type: 'table',
    //   title: 'Équipes',
    //   orderColumn: teamHeadCells[0].id,
    //   headCells: teamHeadCells,
    //   rows: tableTeams,
    // },
    // {
    //   index: 1,
    //   type: 'table',
    //   title: 'Réponses envoyées',
    //   orderColumn: answerSubmissionsHeadCells[0].id,
    //   headCells: answerSubmissionsHeadCells,
    //   rows: answersSubmissions || [],
    // },
    // {
    //   index: 2,
    //   type: 'table',
    //   title: 'Events',
    //   orderColumn: eventsHeadCells[0].id,
    //   headCells: eventsHeadCells,
    //   rows: tableEvents || [],
    // },
    // {
    //   index: 3,
    //   type: 'table',
    //   title: 'Erreurs',
    //   orderColumn: errorsHeadCells[0].id,
    //   headCells: errorsHeadCells,
    //   rows: tableErrors || [],
    // },
    {
      index: 4,
      type: 'extract',
      title: 'Extraction',
    },
  ];

  // type RowType = AdminTableTeam | AdminTableAnswerSubmission;

  return (
    <PublicLayout noBackground>
      <AppBar position='static'>
        <Tabs value={value} onChange={handleChange}>
          {tabs.map(({ index, title }) => (
            <Tab key={index} label={title} id={`simple-tab-${index}`} />
          ))}
          <Tab id={`simple-tab-slack`} icon={
            <div className={classes.slackContainer}>
              <a href='https://rallye-hiver-2021.slack.com' target='_blank' rel='noopener noreferrer'>
                <img src='https://d2n81j17dx4v5l.cloudfront.net/slack.png' alt='Slack' />
              </a>
            </div>
          }>
          </Tab>
        </Tabs>
      </AppBar>
      {/* {tabs.map(({ index, type, title, orderColumn, rows, headCells }) => (
        <TabPanel value={value} index={index} key={index}>
          {type === 'table' && (
            <AdminTable
              title={title}
              orderColumn={orderColumn as keyof RowType}
              headCells={headCells as HeadCell<RowType>[]}
              rows={rows as RowType[]}
            />
          )}
          {type === 'extract' && <AdminExtracts />}
        </TabPanel>
      ))} */}
      <AdminExtracts />
    </PublicLayout>
  );
};

export default AdminHome;
