import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import PublicLayout from '../components/PublicLayout';
import { themeObject } from '../../helpers/style';
import LoadingArea from '../components/LoadingArea';

const theme = createMuiTheme({
  ...themeObject,
  palette: { type: 'dark' },
});

const Loading = (): React.ReactElement => {
  return (
    <PublicLayout>
      <ThemeProvider theme={theme}>
        <LoadingArea />
      </ThemeProvider>
    </PublicLayout>
  );
};

export default Loading;
