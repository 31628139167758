import 'reset-css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider, createMuiTheme, CssBaseline } from '@material-ui/core';
import App from './App';
import store from './store';
import { themeObject } from './helpers/style';
import './index.css';

const theme = createMuiTheme(themeObject);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
