import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Typography, makeStyles, Button } from '@material-ui/core';
import { formatDuration } from '../../helpers/common';

interface CountdownProps {
  startDate: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: `${theme.spacing(6)}px 0`,
  },
  player: {
    padding: '30px 0',
  },
}));

const Countdown = ({ startDate }: CountdownProps): React.ReactElement | null => {
  const [duration, setDuration] = useState<number>();
  const countRef = useRef<NodeJS.Timeout>();
  const classes = useStyles();

  const getDuration = useCallback(() => moment.duration(moment(startDate).diff(moment())).asMilliseconds(), [startDate]);

  const getFormattedDuration = useCallback((duration: number) => formatDuration(duration), []);

  useEffect(() => {
    countRef.current = setInterval(() => {
      setDuration(getDuration());
    }, 1000);
    return (): void => {
      if (countRef.current) {
        clearInterval(countRef.current);
      }
    };
  }, [getDuration]);

  useEffect(() => {
    if (typeof duration === 'number' && duration <= 0 && countRef.current) {
      clearInterval(countRef.current);
    }
  }, [duration]);

  const refreshPage = (): void => {
    window.location.reload(true);
  };

  if (typeof duration !== 'number') {
    return null;
  }

  if (duration <= 0) {
    return (
      <div className={classes.container}>
        <Button variant='contained' onClick={refreshPage}>Recharger la page</Button>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Typography variant='body2' component='div'>Les méchants arrivent dans</Typography>
      <Typography variant='h6'>{getFormattedDuration(duration)}</Typography>
      <div className={classes.player}>
        <audio autoPlay controls src='https://d2n81j17dx4v5l.cloudfront.net/imperial+march.mp3' />
      </div>
    </div>
  );
};

export default Countdown;