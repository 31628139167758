import React from 'react';
import classNames from 'classnames';
import { LinearProgress, makeStyles, Typography, Box } from '@material-ui/core';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import EcoIcon from '@material-ui/icons/Eco';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: `${theme.spacing(6)}px 0`,
  },
  iconContainer: {
    textAlign: 'center',
  },
  progressBarContainer: {
    margin: `0 ${theme.spacing(2)}px`,
    flexGrow: 1
  },
}));

const startDate = process.env.REACT_APP_START_DATE || '2020-12-21T11:02:00+01:00'
const endDate = process.env.REACT_APP_END_DATE || '2021-03-20T23:59:59+01:00'

const TimeProgressLine = () => {
  const classes = useStyles();
  const rallyeStart = moment(startDate);
  const rallyeEnd = moment(endDate);
  const now = moment();
  const totalDaysCount = rallyeEnd.diff(rallyeStart, 'days') + 1;
  const elapsedDaysCount = Math.min(Math.max(now.diff(rallyeStart, 'days') + 1, 0), totalDaysCount);
  const rallyeProgress = elapsedDaysCount / totalDaysCount;
  const rallyeProgressPercent = rallyeProgress * 100;
  return (
    <div className={classes.container}>
      <div className={classNames(classes.iconContainer)}>
        <Typography color='textSecondary'>Solstice d&apos;hiver</Typography>
        <AcUnitIcon color='primary' fontSize='default' />
      </div>
      <div className={classes.progressBarContainer}>
        <Box display='flex' justifyContent='center'>
          <Typography variant='caption' color='textSecondary'>{`Jour ${elapsedDaysCount} / ${totalDaysCount}`}</Typography>
        </Box>
        <LinearProgress variant='determinate' value={rallyeProgressPercent} />
      </div>
      <div className={classNames(classes.iconContainer)}>
        <Typography color='textSecondary'>Équinoxe de printemps</Typography>
        <EcoIcon color='primary' fontSize='default' />
      </div>
    </div>
  )
}

export default TimeProgressLine;