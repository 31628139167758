import { SET_QUESTS, SET_QUEST_DONE, SET_QUESTS_DONE } from '../actionTypes';
import { Quest } from '../../types/quests';
import { QuestsAction } from '../actions';

type StateType = Quest[];

const initialState: StateType = [];

export default (state = initialState, action: QuestsAction): StateType => {
  switch (action.type) {
  case SET_QUESTS:
    return action.payload;
  case SET_QUEST_DONE:
    return state.map(quest => quest.id === action.payload.questId ? {
      ...quest,
      solvedOn: action.payload.solvedOn,
    } : quest);
  case SET_QUESTS_DONE:
    return state.map(quest => {
      const solvedOn = action.payload[quest.id];
      if (!solvedOn) return quest;
      return { ...quest, solvedOn };
    });
  default:
    return state;
  }
};
