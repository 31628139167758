import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormHelperText from '@material-ui/core/FormHelperText';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { setRiddlesSolved, setTeamHasPaid, setTeamName } from '../../store/actions';
import { fetchMyTeam } from '../../services/team';
import { registrationAttempt, getAvailability } from '../../services/auth';
import MemberForm from './MemberForm';
import { themeObject, bigGreenButton, form } from '../../helpers/style';
import {
  createLogin,
  validateRequiredField,
  validateLogin,
  validatePassword,
  validateEmail,
} from '../../helpers/validation';
import CustomTextField from './CustomTextField';
import CustomInputLabel from './CustomInputLabel';
import CustomOutlinedInput from './CustomOutlinedInput';

const useStyles = makeStyles((theme) => ({
  form,
  formElement: {
    margin: '1rem 0',
  },
  bigGreenButton,
}));

const theme = createMuiTheme({ ...themeObject, palette: { type: 'light' }, });

const RegisterForm = (): React.ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [exampleLogin, setExampleLogin] = useState<string>();
  const [name, setName] = useState<string>();
  const [login, setLogin] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);
  const [firstname, setFirstname] = useState<string>();
  const [lastname, setLastname] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [serverError, setServerError] = useState<string>();
  const [loginError, setLoginError] = useState<string>();

  useEffect(() => { setExampleLogin(createLogin(name)); }, [name, setExampleLogin]);

  const makeRegisterAttempt = async (e: React.FormEvent) => {
    e.preventDefault();
    setHasSubmitted(true);
    const hasErrors = !validatePassword(password)
      || !validateLogin(login)
      || !validateRequiredField(name)
      || !validateRequiredField(firstname)
      || !validateRequiredField(lastname)
      || !validateEmail(email);
    if (hasErrors) return;
    const { isLoginAvailable } = await getAvailability(login || '');
    if (!isLoginAvailable) {
      setLoginError('Un équipe avec ce login existe déjà, veuillez en choisir un autre');
      setHasSubmitted(false);
      return;
    }
    try {
      await registrationAttempt({ login, password, name, firstname, lastname, email });
      const { solvedRiddles, hasPaid, name: teamName } = await fetchMyTeam();
      dispatch(setTeamName(teamName));
      dispatch(setTeamHasPaid(hasPaid));
      dispatch(setRiddlesSolved(solvedRiddles));
      history.push('/');
    } catch (err) {
      setServerError(err.message);
    } finally {
      setHasSubmitted(false);
    }
  };
  const getRequiredFieldHelperText = ({ value = '', displayName = '' }): string => (
    value && !validateRequiredField(value) ? `${displayName} est un champs requis.` : ''
  );
  const loginHelperText = 'Le login de connexion ne doit pas contenir d\'espaces, de majuscules ou de caractères spéciaux.';
  const passwordHelperText = 'Le mot de passe doit répondre aux règles suivantes: un minimum de 8 caractères, au moins une majuscule, au moins une minuscule et au moins un chiffre';
  const loginHelperTextWithExample = loginHelperText + (exampleLogin ? ` Proposition : ${exampleLogin}` : '');
  const passwordValidationError = password && !validatePassword(password);
  const passwordErrorMessage = passwordValidationError ? passwordHelperText : serverError;
  const emailHelperText = `L'adresse e-mail n'est pas valide.`;

  return (
    <ThemeProvider theme={theme}>
      <Paper elevation={0} className={classes.form}>
        <form onSubmit={makeRegisterAttempt}>
          <Box>
            <Box display='flex' justifyContent='center'>
              <Typography variant='h6' paragraph>Votre équipe</Typography>
            </Box>
            <CustomTextField
              variant='outlined'
              className={classes.formElement}
              required
              fullWidth
              label='Nom de votre équipe'
              onChange={({ target: { value } }) => setName(value)}
              {...!validateRequiredField(name) && hasSubmitted && { helperText: 'Le nom est vide.', error: true }}
              {...serverError && { error: true }}
            />
            <CustomTextField
              variant='outlined'
              required
              fullWidth
              className={classes.formElement}
              label='Login de votre équipe'
              onChange={({ target: { value } }) => {
                setLogin(value);
                if (loginError) { setLoginError('') }
              }}
              {...exampleLogin && { helperText: `Exemple : ${exampleLogin}` }}
              {...login && !validateLogin(login) && { helperText: loginHelperTextWithExample, error: true }}
              {...serverError && { error: true }}
              {...loginError && { error: true, helperText: loginError }}
            />
            <FormControl variant='outlined' className={classes.formElement} fullWidth required>
              <CustomInputLabel
                htmlFor='password'
                {...passwordErrorMessage && { error: true }}
              >
                Mot de passe
              </CustomInputLabel>
              <CustomOutlinedInput
                id='password'
                type={showPassword ? 'text' : 'password'}
                onChange={({ target: { value } }) => setPassword(value)}
                {...passwordErrorMessage && { error: true }}
                labelWidth={120}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(show => !show)}
                      onMouseDown={() => setShowPassword(show => !show)}
                      edge='end'
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {passwordErrorMessage && <FormHelperText error>{passwordErrorMessage}</FormHelperText>}
            </FormControl>
          </Box>
          <Box className={classes.formElement}>
            <Box display='flex' justifyContent='center'>
              <Typography variant='h6' paragraph>Chef d&apos;équipe</Typography>
            </Box>
            <MemberForm
              memberType={`chef d'équipe`}
              fieldClassName={classes.formElement}
              onFirstNameChange={value => setFirstname(value)}
              onLastNameChange={value => setLastname(value)}
              onEmailChange={value => setEmail(value)}
              serverError={serverError}
              firstNameError={getRequiredFieldHelperText({ value: firstname, displayName: 'Le prénom' })}
              lastNameError={getRequiredFieldHelperText({ value: lastname, displayName: 'Le nom' })}
              emailError={(email && !validateEmail(email) && emailHelperText) || ''}
              needsEmail
            />
          </Box>
          <Button
            fullWidth
            type='submit'
            variant='outlined'
            size='large'
            className={classNames(classes.bigGreenButton, classes.formElement)}
          >
            S&apos;inscrire
          </Button>
        </form>
      </Paper>
    </ThemeProvider >
  );
};

export default RegisterForm;
