import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { setTeamName, setTeamHasPaid, setRiddlesSolved, setQuestsDone } from '../../store/actions';
import { fetchMyTeam } from '../../services/team';
import { loginAttempt } from '../../services/auth';
import { validateLogin } from '../../helpers/validation';
import { LoginDoesNotExistError, InvalidPasswordError } from '../../helpers/request';
import { themeObject, bigGreenButton, form } from '../../helpers/style';
import CustomOutlinedInput from './CustomOutlinedInput';
import CustomInputLabel from './CustomInputLabel';
import CustomTextField from './CustomTextField';

const useStyles = makeStyles(() => ({
  form,
  formElement: {
    margin: '1rem 0',
  },
  bigGreenButton,
}));

const theme = createMuiTheme({
  ...themeObject,
  palette: { type: 'light' },
});

interface LoginFormProps {
  redirectAfterLogin: () => void | undefined;
}

const LoginForm = ({ redirectAfterLogin }: LoginFormProps): React.ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState<string>();
  const [passwordError, setPasswordError] = useState<string>();
  const [unknownError, setUnknownError] = useState<string>();

  const makeLoginAttempt = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    setLoginError(undefined);
    setPasswordError(undefined);
    setUnknownError(undefined);
    setHasSubmitted(true);
    if (!password || !validateLogin(login)) return;
    try {
      await loginAttempt({ login, password });
      const { questsDone, solvedRiddles, hasPaid, name } = await fetchMyTeam();
      dispatch(setTeamName(name));
      dispatch(setTeamHasPaid(hasPaid));
      dispatch(setQuestsDone(questsDone));
      dispatch(setRiddlesSolved(solvedRiddles));
      if (redirectAfterLogin) {
        redirectAfterLogin();
      }
    } catch (err) {
      if (err instanceof LoginDoesNotExistError) {
        setLoginError(err.message);
      } else if (err instanceof InvalidPasswordError) {
        setPasswordError(err.message);
      } else {
        setUnknownError(err.message);
      }
    } finally {
      setHasSubmitted(false);
    }
  };

  const passwordErrorMessage = !password && hasSubmitted && 'Le mot de passe est requis';

  return (
    <ThemeProvider theme={theme}>
      <Paper elevation={0} className={classes.form}>
        <Box display='flex' justifyContent='center'>
          <Typography variant='h6' paragraph>Connexion</Typography>
        </Box>
        <form onSubmit={makeLoginAttempt}>
          <FormControl variant='outlined' fullWidth className={classes.formElement}>
            <CustomTextField
              variant='outlined'
              required
              label='Login de votre équipe'
              onChange={({ target: { value } }) => setLogin(value)}
              {...!validateLogin(login) && hasSubmitted && {
                helperText: 'Le login est vide.',
                error: true,
              }}
              {...loginError && { helperText: loginError, error: true }}
            />
          </FormControl>
          <FormControl variant='outlined' fullWidth required className={classes.formElement}>
            <CustomInputLabel htmlFor='password'>Mot de passe</CustomInputLabel>
            <CustomOutlinedInput
              id='password'
              type={showPassword ? 'text' : 'password'}
              onChange={({ target: { value } }) => setPassword(value)}
              labelWidth={120}
              error={!!passwordErrorMessage || !!passwordError}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowPassword(show => !show)}
                    onMouseDown={() => setShowPassword(show => !show)}
                    edge='end'
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {passwordErrorMessage && <FormHelperText error>{passwordErrorMessage}</FormHelperText>}
            {passwordError && <FormHelperText error>{passwordError}</FormHelperText>}
          </FormControl>
          {unknownError != null && <FormHelperText error>Oups, une erreur est survenue...</FormHelperText>}
          <Button
            fullWidth={true}
            type='submit'
            variant='outlined'
            size='large'
            className={classNames(classes.bigGreenButton, classes.formElement)}
          >
            Se connecter
          </Button>
          <Box display='flex' justifyContent='center'>
            <Link to='/forgotten-password'>Mot de passe oublié ?</Link>
          </Box>
        </form>
      </Paper>
    </ThemeProvider>
  );
};

export default LoginForm;
