import React from 'react';
import { useRouteMatch, Redirect, match } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Quest as QuestType } from '../../types/quests';
import PuzzleContent from './PuzzleContent';
import LoadingArea from './LoadingArea';


const Quest = (): React.ReactElement => {
  const routeMatch: match<{ id: string }> = useRouteMatch();
  const quests = useSelector<RootState>(state => state.quests) as QuestType[];
  const questId = routeMatch.params ? routeMatch.params.id : null;
  const quest = quests ? quests.find(quest => quest.id === questId) : null;

  if (quests.length === 0) {
    return <LoadingArea />;
  }
  if (!quest) {
    return <Redirect to='/404' />;
  }
  return (
    <PuzzleContent
      key={quest.id}
      puzzleId={quest.id}
      solvedOn={quest.solvedOn}
      pdfURL={quest.pdfURL}
      backgroundAudioFile={quest.backgroundAudioFile}
      puzzleType="quest"
    />
  );
};

export default Quest;
