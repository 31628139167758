import React, { ReactElement } from 'react';
import { makeStyles, Switch, Grid } from '@material-ui/core';
import DownloadFile from './DownloadFile';

interface PdfVersionHeaderProps {
  pdfURL?: string;
  pdfPrintURL?: string;
  audioFile?: string;
  videoURL?: string;
  showPrintVersion: boolean;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '60px',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
  },
  grid: {
    width: 'auto',
    flexGrow: 1,
  },
}));

const PdfVersionHeader = ({ pdfURL, pdfPrintURL, audioFile, videoURL, showPrintVersion, handleChange }: PdfVersionHeaderProps): ReactElement => {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      {pdfPrintURL && (
        <Grid container alignItems='center' justify='center' className={classes.grid}>
          <Grid item>Version originale</Grid>
          <Grid item>
            <Switch
              checked={showPrintVersion}
              onChange={handleChange}
              color='primary'
              name='pdfVersion'
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Grid>
          <Grid item>Version imprimable</Grid>
        </Grid>
      )}
      {audioFile && <DownloadFile url={audioFile} title='Télécharger le fichier audio' icon='audio' />}
      {pdfPrintURL && <DownloadFile url={pdfPrintURL} title='Télécharger le PDF imprimable' icon='print' />}
      {pdfURL && <DownloadFile url={pdfURL} title='Télécharger le PDF original' />}
      {videoURL && <DownloadFile url={videoURL} title='Télécharger le fichier vidéo' icon='video' />}
    </div>
  );
};

export default PdfVersionHeader;
