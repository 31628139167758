import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import PublicLayout from '../components/PublicLayout';
import PrivateLayout from '../components/PrivateLayout';
import { getIsLoggedIn } from '../../helpers/auth';
import PdfDocument from '../components/PdfDocument';
import DownloadFile from '../components/DownloadFile';


const RULES_URL = 'https://d2n81j17dx4v5l.cloudfront.net/r%C3%A8gles.pdf';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  file: {
    maxWidth: '1000px',
    width: '100%',
  },
  icon: {
    justifyContent: 'end',
    display: 'flex',
  },
}));

const Rules = (): React.ReactElement => {
  const classes = useStyles();
  const isLoggedIn = getIsLoggedIn();

  const Layout = isLoggedIn ? PrivateLayout : PublicLayout;

  return (
    <Layout>
      <Container maxWidth={isLoggedIn ? false : 'lg'} disableGutters={isLoggedIn} className={classes.container}>
        <div className={classes.file}>
          <Box className={classes.icon}>
            <DownloadFile url={RULES_URL} title='Télécharger le PDF' />
          </Box>
          <PdfDocument url={RULES_URL} />
        </div>
      </Container>
    </Layout>
  );
};

export default Rules;
