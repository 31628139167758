import React from 'react';
import { TextField, makeStyles, OutlinedTextFieldProps, colors } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  cssLabel: {
    '&$cssFocused': {
      color: `${colors.teal[800]} !important`,
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${colors.teal[800]} !important`,
    },
  },
  cssFocused: {},
  notchedOutline: {},
}));

const CustomTextField = ({ InputLabelProps, InputProps, ...props }: OutlinedTextFieldProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <TextField
      {...props}
      variant='outlined'
      InputLabelProps={{
        ...InputLabelProps,
        classes: {
          root: classes.cssLabel,
          focused: classes.cssFocused,
        },
      }}
      InputProps={{
        ...InputProps,
        classes: {
          root: classes.cssOutlinedInput,
          focused: classes.cssFocused,
          notchedOutline: classes.notchedOutline,
        },
      }}
    />
  );
};

export default CustomTextField;
