import { SET_TEAM_NAME, SET_TEAM_HAS_PAID } from '../actionTypes';
import { TeamAction } from '../actions';

const initialState = {
  hasPaid: false,
  name: '',
};

export default (state = initialState, action: TeamAction) => {
  switch (action.type) {
    case SET_TEAM_NAME:
      return { ...state, name: action.payload };
    case SET_TEAM_HAS_PAID:
      return { ...state, hasPaid: action.payload };
    default:
      return state;
  };
};
