import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import useDimensions from '../../hooks/useDimensions';
import PrivateLayout from '../components/PrivateLayout';
import PdfDocument from '../components/PdfDocument';
import DownloadFile from '../components/DownloadFile';
import ReactFlashlight from '../components/FlashLight';
import { foundRiddle } from '../../services/riddle';

type StyleProps = {
  height: number;
  width: number;
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  lightContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  file: {
    maxWidth: '1376px',
    width: '100%',
  },
  icon: {
    justifyContent: 'end',
    display: 'flex',
  },
  magicSquare: ({ height = 0, width = 0 }: StyleProps) => ({
    height: `${width / 30}px`,
    width: `${width / 30}px`,
    cursor: 'pointer',
    position: 'relative',
    top: `${-height / 1.7}px`,
    left: `${width / 1.14}px`,
  }),
}));

const QuestMap = (): React.ReactElement => {
  const [torchMode, setTorchMode] = useState(false);
  const [reload, setReload] = useState(false);
  const [ref, { height, width }] = useDimensions({ reload });
  const classes = useStyles({ height, width });

  const triggerTorch = (): void => {
    setTorchMode(!torchMode);
    foundRiddle('enigme-mystere');
  };

  const onLoadSuccess = (): void => {
    // To wait for the document to be fully displayed, othenwise we don't have the correct dimensions
    setTimeout(() => { setReload(!reload); }, 1000);
  };

  const pdfURL = 'https://d2n81j17dx4v5l.cloudfront.net/Carte_Parcours.pdf';
  const hiddenMapURL = 'https://d2n81j17dx4v5l.cloudfront.net/Map_Vincennes.pdf';

  return (
    <PrivateLayout>
      {!torchMode && (
        <div className={classes.container}>
          <div className={classes.file}>
            <Box className={classes.icon}>
              <DownloadFile url={pdfURL} title='Télécharger le PDF' />
            </Box>
            <div className={classes.lightContainer}>
              <div ref={ref}>
                <PdfDocument url={pdfURL} onLoadSuccess={onLoadSuccess} />
              </div>
              <div className={classes.magicSquare} onClick={triggerTorch} />
            </div>
          </div>
        </div>
      )}
      {torchMode && (
        <div className={classes.container} onClick={triggerTorch}>
          <ReactFlashlight>
            <PdfDocument url={hiddenMapURL} className={classes.file} onLoadSuccess={onLoadSuccess} />
          </ReactFlashlight>
        </div>
      )}
    </PrivateLayout>
  );
};

export default QuestMap;
