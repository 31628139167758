import React from 'react';
import { createMuiTheme, Box, Container, makeStyles, Paper, Typography, ThemeProvider } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import { themeObject } from '../../helpers/style';

const useStyles = makeStyles(() => ({
  footerContainer: {
    boxShadow: 'none',
    borderRadius: '0px',
  },
  footer: {
    minHeight: '50px',
  },
}));

const theme = createMuiTheme({
  ...themeObject,
  palette: {
    type: 'dark',
  },
});

const Footer = (): React.ReactElement => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Paper className={classes.footerContainer}>
        <Container maxWidth='lg'>
          <Box display='flex' alignItems='center' className={classes.footer}>
            <Box flexGrow={1} display='flex'>
              <Typography variant='caption' color='textSecondary'>© 2020 Les Loulous Inc</Typography>
            </Box>
            <Box>
              <a href='mailto:rallye.hiver.2021@gmail.com'>
                <EmailIcon color='action' />
              </a>
            </Box>
          </Box>
        </Container>
      </Paper>
    </ThemeProvider>
  );
};

export default Footer;
