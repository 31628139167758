import React, { useState, ReactElement } from 'react';
import { makeStyles } from '@material-ui/core';
import { SolvedOn, PuzzleType } from '../../types/puzzles';
import PdfDocument from './PdfDocument';
import PdfVersionHeader from './PdfVersionHeader';
import QuestHeader from './QuestHeader';
import RiddleHeader from './RiddleHeader';
import TimedRiddle from './TimedRiddle';
import Chronometer from './Chronometer';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  centered: {
    maxWidth: '1000px',
    width: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '60px',
    flexWrap: 'wrap',
  },
  video: {
    width: '100%',
  },
}));

interface PuzzleContentProps {
  puzzleId: string;
  puzzleType: PuzzleType;
  pdfURL?: string;
  pdfPrintURL?: string;
  audioFile?: string;
  backgroundAudioFile?: string;
  solvedOn?: SolvedOn;
  timed?: boolean;
  startDate?: string;
  gifURL?: string;
  videoURL?: string;
  step1Date?: string;
}

const PuzzleContent = ({
  puzzleId,
  pdfURL,
  pdfPrintURL,
  audioFile,
  backgroundAudioFile,
  solvedOn,
  puzzleType,
  timed,
  startDate,
  gifURL,
  videoURL,
  step1Date,
}: PuzzleContentProps): ReactElement => {
  const classes = useStyles();
  const [showPrintVersion, setShowPrintVersion] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setShowPrintVersion(event.target.checked);
  };

  // only the timed riddle has a gif
  if (gifURL) {
    return <TimedRiddle gifURL={gifURL} />;
  }

  return (
    <div className={classes.container}>
      <div className={classes.centered}>
        {timed && startDate && !solvedOn && <Chronometer startDate={startDate} />}
        <PdfVersionHeader
          pdfURL={pdfURL}
          pdfPrintURL={pdfPrintURL}
          audioFile={audioFile}
          videoURL={videoURL}
          showPrintVersion={showPrintVersion}
          handleChange={handleChange}
        />
        <div className={classes.header}>
          {puzzleType === 'quest' && <QuestHeader puzzleId={puzzleId} solvedOn={solvedOn} />}
          {puzzleType === 'riddle' && (
            <RiddleHeader
              puzzleId={puzzleId}
              solvedOn={solvedOn}
              timed={!!timed}
              startDate={startDate}
              step1Date={step1Date}
            />
          )}
        </div>
        {backgroundAudioFile && <audio controls autoPlay src={backgroundAudioFile} />}
        {audioFile && <audio controls src={audioFile} />}
        {pdfURL && <PdfDocument url={showPrintVersion && pdfPrintURL ? pdfPrintURL : pdfURL} />}
        {videoURL && <video controls src={videoURL} className={classes.video} />}
      </div>
    </div>
  );
};

export default PuzzleContent;