import { SET_RIDDLES, SET_RIDDLE_SOLVED, SET_RIDDLES_SOLVED, SET_RIDDLE } from '../actionTypes';
import { Riddle } from '../../types/riddles';
import { RiddlesAction } from '../actions';

type RiddlesState = Riddle[];

const initialState: RiddlesState = [];

export default (state = initialState, action: RiddlesAction): RiddlesState => {
  switch (action.type) {
    case SET_RIDDLES:
      return action.payload;
    case SET_RIDDLE:
      return state.map(riddle => riddle.id === action.payload.id ? action.payload : riddle);
    case SET_RIDDLE_SOLVED:
      return state.map(riddle => riddle.id === action.payload.riddleId ? {
        ...riddle,
        solvedOn: action.payload.solvedOn,
      } : riddle);
    case SET_RIDDLES_SOLVED:
      return state.map(riddle => {
        const solvedOn = action.payload[riddle.id];
        if (!solvedOn) return riddle;
        return { ...riddle, solvedOn };
      });
    default:
      return state;
  }
};
