import { parseOrThrowError } from '../helpers/request';
import { getToken } from '../helpers/auth';
import { SolvedOn } from '../types/puzzles';
import { QuestIdType } from '../types/quests';

const API_URI = process.env.REACT_APP_API_URI;


export type MarkQuestDoneResponse = {
  solvedOn: SolvedOn;
};

export const markQuestDone = async (questId: QuestIdType): Promise<MarkQuestDoneResponse> => {
  const token = getToken();
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);
  headers.append('Content-Type', 'application/json');

  const response = await fetch(`${API_URI}/quest/mark-done`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ questId }),
    cache: 'no-store',
  });

  return parseOrThrowError<MarkQuestDoneResponse>(response);
};


export type LoadQuestsResponse = {
  id: QuestIdType;
  name: string;
  pdfURL: string;
  solvedOn: SolvedOn;
}[];

export const loadQuests = async (): Promise<LoadQuestsResponse> => {
  const token = getToken();
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);
  headers.append('Content-Type', 'application/json');

  const response = await fetch(`${API_URI}/quest/load`, {
    method: 'GET',
    headers,
    cache: 'no-store',
  });

  return parseOrThrowError<LoadQuestsResponse>(response);
};
