import React, { useCallback, ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Button, colors } from '@material-ui/core';
import { markQuestDone } from '../../services/quests';
import { setQuestDone } from '../../store/actions';
import { SolvedOn } from '../../types/puzzles';
import { getFormattedDateTime } from '../../helpers/common';

interface QuestHeaderProps {
  puzzleId: string;
  solvedOn?: SolvedOn;
}

const useStyles = makeStyles((theme) => ({
  solved: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    borderRadius: '4px',
    cursor: 'default',
    backgroundColor: colors.teal[800],
  }
}));

const QuestHeader = ({ puzzleId, solvedOn }: QuestHeaderProps): ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const markAsDone = useCallback(async () => {
    const response = await markQuestDone(puzzleId);
    if (response.solvedOn) {
      dispatch(setQuestDone(puzzleId, response.solvedOn));
    }
  }, [dispatch, puzzleId]);

  if (solvedOn) {
    return <div className={classes.solved}>Parcours marqué comme fait le {getFormattedDateTime(solvedOn)}.</div>
  }

  return (
    <Button variant='contained' color='primary' size='medium' onClick={markAsDone}>Marquer le parcours comme fait.</Button>
  );
}

export default QuestHeader;
