import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Container, makeStyles, Typography } from '@material-ui/core';
import PublicLayout from '../components/PublicLayout';

const useStyles = makeStyles(() => ({
  banner: {
    paddingBottom: '8rem',
    paddingTop: '8rem',
  },
  image: {
    marginTop: '4rem',
  },
}));

const NotFound = (): React.ReactElement => {
  const classes = useStyles();
  const history = useHistory();

  const goToHome = (): void => history.push('/');

  return (
    <PublicLayout>
      <Container className={classes.banner}>
        <Box display='flex' alignItems='center' flexDirection='column'>
          <Typography variant='h1' gutterBottom>Vous vous êtes perdu.</Typography>
          <Button onClick={goToHome} variant='contained' color='primary'>Retourner à l'accueil</Button>
        </Box>
      </Container>
    </PublicLayout>
  );
};

export default NotFound;
