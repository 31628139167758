import React from 'react';
import { InputLabel, makeStyles, InputLabelProps, colors } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  cssLabel: {
    '&$cssFocused': {
      color: `${colors.teal[800]} !important`,
    }
  },
  cssFocused: {},
}));

const CustomInputLabel = (props: InputLabelProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <InputLabel
      {...props}
      classes={{
        root: classes.cssLabel,
        focused: classes.cssFocused,
      }}
    />
  );
};

export default CustomInputLabel;