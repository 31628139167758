import { useState, useCallback, useLayoutEffect } from 'react';

interface DimensionObject {
  width: number;
  height: number;
  top: number;
  left: number;
  x: number;
  y: number;
  right: number;
  bottom: number;
}

type UseDimensionsHook = [
  (node: HTMLDivElement) => void,
  DimensionObject,
  HTMLDivElement | undefined
];

interface UseDimensionsArgs {
  liveMeasure?: boolean;
  reload?: boolean;
}

const getDimensionObject = (node: HTMLDivElement): DimensionObject => (
  node.getBoundingClientRect()
);

const useDimensions = ({ liveMeasure = true, reload = false }: UseDimensionsArgs = {}): UseDimensionsHook => {
  const emptyDimensions = { width: 0, height: 0, top: 0, bottom: 0, x: 0, y: 0, left: 0, right: 0 };
  const [dimensions, setDimensions] = useState(emptyDimensions);
  const [node, setNode] = useState<HTMLDivElement>();

  const ref = useCallback(node => {
    setNode(node);
  }, []);

  useLayoutEffect(() => {
    if (node) {
      const measure = () => (
        window.requestAnimationFrame(() => setDimensions(getDimensionObject(node)))
      );
      measure();

      if (liveMeasure) {
        window.addEventListener('resize', measure);
        window.addEventListener('scroll', measure);

        return () => {
          window.removeEventListener('resize', measure);
          window.removeEventListener('scroll', measure);
        };
      }
    }
  }, [node, liveMeasure, reload]);

  return [ref, dimensions, node];
}

export default useDimensions;