import React from 'react';
import { OutlinedInput, makeStyles, OutlinedInputProps, colors } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${colors.teal[800]} !important`,
    }
  },
  cssFocused: {},
  notchedOutline: {},
}));

const CustomOutlinedInput = (props: OutlinedInputProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <OutlinedInput
      {...props}
      classes={{
        root: classes.cssOutlinedInput,
        focused: classes.cssFocused,
        notchedOutline: classes.notchedOutline,
      }}
    />
  );
};

export default CustomOutlinedInput;