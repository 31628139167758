import classNames from 'classnames';
import React, { useState, useEffect, useCallback } from 'react';
import { match, useRouteMatch, useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { reinitiatePassword, getLoginFromPasswordKey } from '../../services/auth';
import { themeObject, bigGreenButton, form } from '../../helpers/style';
import { validatePassword } from '../../helpers/validation';
import CustomTextField from './CustomTextField';
import CustomInputLabel from './CustomInputLabel';
import CustomOutlinedInput from './CustomOutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
  form,
  formElement: {
    margin: '1rem 0',
  },
  bigGreenButton,
}));

const theme = createMuiTheme({ ...themeObject, palette: { type: 'light' }, });

const PasswordResetForm = (): React.ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const routeMatch: match<{ key: string }> = useRouteMatch();
  const key = routeMatch.params ? routeMatch.params.key : null;

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);
  const [serverError, setServerError] = useState('');
  const [sendingPassword, setSendingPassword] = useState(false);

  const fetchTeamLogin = useCallback(async () => {
    if (key) {
      const { login } = await getLoginFromPasswordKey(key);
      setLogin(login)
    }
  }, [key])

  useEffect(() => {
    fetchTeamLogin();
  }, [fetchTeamLogin]);

  const sendNewPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    const hasErrors = !validatePassword(password);
    if (sendingPassword || hasErrors || !key || !password) return;
    setSendingPassword(true);
    try {
      await reinitiatePassword({ key, password });
      history.push('/');
    } catch (err) {
      setServerError(err.message);
    }
  };

  const passwordHelperText = 'Le mot de passe doit répondre aux règles suivantes: un minimum de 8 caractères, au moins une majuscule, au moins une minuscule et au moins un chiffre';
  const passwordValidationError = password && !validatePassword(password);
  const passwordErrorMessage = passwordValidationError ? passwordHelperText : serverError;

  return (
    <ThemeProvider theme={theme}>
      <Paper elevation={0} className={classes.form}>
        <form onSubmit={sendNewPassword}>
          <Box>
            <CustomTextField
              variant='outlined'
              required
              fullWidth
              disabled
              className={classes.formElement}
              label='Login de votre équipe'
              value={login}
            />
            <FormControl variant='outlined' className={classes.formElement} fullWidth required>
              <CustomInputLabel
                htmlFor='password'
                {...passwordErrorMessage && { error: true }}
              >
                Mot de passe
              </CustomInputLabel>
              <CustomOutlinedInput
                id='password'
                type={showPassword ? 'text' : 'password'}
                onChange={({ target: { value } }) => setPassword(value)}
                {...passwordErrorMessage && { error: true }}
                labelWidth={120}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(show => !show)}
                      onMouseDown={() => setShowPassword(show => !show)}
                      edge='end'
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {passwordErrorMessage && <FormHelperText error>{passwordErrorMessage}</FormHelperText>}
            </FormControl>
          </Box>
          <Button
            fullWidth
            type='submit'
            variant='outlined'
            size='large'
            className={classNames(classes.bigGreenButton, classes.formElement)}
          >
            {sendingPassword ? <CircularProgress color='inherit' /> : 'Valider'}
          </Button>
        </form>
      </Paper>
    </ThemeProvider >
  );
};

export default PasswordResetForm;
