import { parseOrThrowError } from '../helpers/request';
import { setToken } from '../helpers/auth';

const API_URI = process.env.REACT_APP_API_URI;

type LoginAttemptResponseType = {
  token: string;
};

type LoginAttemptProps = {
  login: string;
  password: string;
};

export const loginAttempt = async ({ login, password }: LoginAttemptProps): Promise<LoginAttemptResponseType> => {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  const response = await fetch(`${API_URI}/auth/signin`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ login, password }),
    cache: 'no-store',
  });

  const { token } = await parseOrThrowError<LoginAttemptResponseType>(response);
  setToken(token);
  return { token };
};

type RegistrationAttemptResponseType = {
  token: string;
};

type RegistrationAttemptProps = {
  login?: string;
  password?: string;
  name?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
};

export const registrationAttempt = async ({ login, password, name, firstname, lastname, email }: RegistrationAttemptProps): Promise<RegistrationAttemptResponseType> => {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  const response = await fetch(`${API_URI}/auth/signup`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ login, password, name, firstname, lastname, email }),
    cache: 'no-store',
  });

  const { token } = await parseOrThrowError<RegistrationAttemptResponseType>(response);
  setToken(token);
  return { token };
};


interface FetchAvailabilityResponse {
  isLoginAvailable: boolean;
}

export const getAvailability = async (login: string): Promise<FetchAvailabilityResponse> => {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  const response = await fetch(`${API_URI}/auth/availability?login=${login}`, {
    method: 'GET',
    headers,
    cache: 'no-store',
  });
  return parseOrThrowError<FetchAvailabilityResponse>(response);
};

interface FetchPasswordEmailResponse {
  emailSent: boolean;
}

export const sendPasswordReinitialisationEmail = async (login: string): Promise<FetchPasswordEmailResponse> => {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  const response = await fetch(`${API_URI}/auth/forgotten-password`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ login }),
    cache: 'no-store',
  });
  return parseOrThrowError<FetchPasswordEmailResponse>(response);
};

interface FetchLoginResponse {
  login: string;
}

export const getLoginFromPasswordKey = async (key: string): Promise<FetchLoginResponse> => {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  const response = await fetch(`${API_URI}/auth/team-login?key=${key}`, {
    method: 'GET',
    headers,
    cache: 'no-store',
  });
  return parseOrThrowError<FetchLoginResponse>(response);
};

type PasswordReinitialisationProps = {
  key: string,
  password: string,
}

type PasswordReinitialisationResponseType = {
  passwordReset: boolean,
}

type PasswordResetResponseType = {
  passwordReset: boolean,
  token: string,
}

export const reinitiatePassword = async ({ key, password }: PasswordReinitialisationProps): Promise<PasswordReinitialisationResponseType> => {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  const response = await fetch(`${API_URI}/auth/password-reset`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ key, password }),
    cache: 'no-store',
  });

  const { token, passwordReset } = await parseOrThrowError<PasswordResetResponseType>(response);
  setToken(token);
  return { passwordReset };
};
