import { Riddle, RiddleIdType } from '../types/riddles';
import { parseOrThrowError } from '../helpers/request';
import { getToken } from '../helpers/auth';
import { SolvedOn } from '../types/puzzles';

const API_URI = process.env.REACT_APP_API_URI;


export type SubmitRiddleAnswerResponse = {
  solvedOn: SolvedOn;
  failMessage?: string;
};

type SubmitRiddleAnswerProps = {
  riddleId: RiddleIdType,
  answer: string,
  failMessage?: string
}

export const submitRiddleAnswer = async ({ riddleId, answer, failMessage }: SubmitRiddleAnswerProps): Promise<SubmitRiddleAnswerResponse> => {
  const token = getToken();
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);
  headers.append('Content-Type', 'application/json');

  const response = await fetch(`${API_URI}/riddle/solve`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ riddleId, answer, ...failMessage && { failMessage } }),
    cache: 'no-store',
  });

  return parseOrThrowError<SubmitRiddleAnswerResponse>(response);
};

export type LoadRiddlesResponse = Riddle[];

export const loadRiddles = async (): Promise<LoadRiddlesResponse> => {
  const token = getToken();
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);
  headers.append('Content-Type', 'application/json');

  const response = await fetch(`${API_URI}/riddle/load`, {
    method: 'GET',
    headers,
    cache: 'no-store',
  });

  return parseOrThrowError<LoadRiddlesResponse>(response);
};

export type StartRiddleResponse = Riddle;

export const startRiddle = async (riddleId: RiddleIdType): Promise<StartRiddleResponse> => {
  const token = getToken();
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);
  headers.append('Content-Type', 'application/json');

  const response = await fetch(`${API_URI}/riddle/start`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ riddleId }),
    cache: 'no-store',
  });

  return parseOrThrowError<StartRiddleResponse>(response);
};

export type FoundRiddleResponse = Riddle;

export const foundRiddle = async (riddleId: RiddleIdType): Promise<FoundRiddleResponse> => {
  const token = getToken();
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);
  headers.append('Content-Type', 'application/json');

  const response = await fetch(`${API_URI}/riddle/found`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ riddleId }),
    cache: 'no-store',
  });

  return parseOrThrowError<FoundRiddleResponse>(response);
};

export type StepRiddleResponse = Riddle;

export const fetchRiddleStep = async (riddleId: RiddleIdType, step: string): Promise<StepRiddleResponse> => {
  const token = getToken();
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);
  headers.append('Content-Type', 'application/json');

  const response = await fetch(`${API_URI}/riddle/step`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ riddleId, step }),
    cache: 'no-store',
  });

  return parseOrThrowError<StepRiddleResponse>(response);
};