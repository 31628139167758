import React, { useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import LoginForm from '../forms/LoginForm';
import Info from '../components/Info';
import { getIsLoggedIn } from '../../helpers/auth';
import PublicLayout from '../components/PublicLayout';
import Logo from '../components/Logo';

const useStyles = makeStyles(() => ({
  container: {
    paddingBottom: '3rem',
  },
  bold: {
    fontWeight: 700,
  },
  logo: {
    width: '20rem',
    marginBottom: '3rem',
  },
  blocked: {
    color: '#fff',
    margin: '0.5rem'
  },
  box: {
    backgroundColor: '#d32f2f',
    borderRadius: '6px',
  }
}));

const Login = (): React.ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const state = location.state as any; // todo clean this
  const redirectTo = (state && state.from && state.from.pathname) || '/';
  const isLoggedIn = getIsLoggedIn();

  const redirectAfterLogin = useCallback(() => {
    history.push(redirectTo);
  }, [history, redirectTo]);

  useEffect(() => {
    if (isLoggedIn) {
      redirectAfterLogin();
    }
  }, [redirectAfterLogin, isLoggedIn]);
  return (
    <PublicLayout>
      <Container maxWidth='lg' className={classes.container}>
        {/* to fix unwanted horizontal scroll on mobile */}
        <Container maxWidth={false}>
          <Grid container spacing={6}>
            <Grid item md={6} sm={12}>
              <Box display='flex' justifyContent='center'>
                <Logo className={classes.logo} />
              </Box>
              <Typography paragraph variant='subtitle2' className={classes.bold}>Chaque année depuis plus de 50 ans, le Rallye d’Hiver vous propose de découvrir un Paris pittoresque à travers le prisme d’une thématique particulière (précédemment la musique, les jeux, le luxe, le temps...).</Typography>
              <Typography paragraph variant='h5'>Cette année, lancez-vous dans une poursuite d’enfer contre Les Méchants, du 21 décembre 2020 au 21 mars 2021.</Typography>
              <Box display='flex' justifyContent='center' className={classes.box}>
                <Typography variant='body1' className={classes.blocked}>Les inscriptions sont maintenant closes.</Typography>
              </Box>
            </Grid>
            <Grid item md sm>
              <LoginForm redirectAfterLogin={redirectAfterLogin} />
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Info />
    </PublicLayout>
  );
};

export default Login;
