import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import PrivateLayout from '../components/PrivateLayout';
import Statistics from '../components/Statistics';
import { RootState } from '../../store';
import TimeProgressLine from '../components/TimeProgressLine';
import Charts from '../components/Charts';
import Countdown from '../components/Countdown';
import { fetchTeamStats, FetchTeamStatsResponse } from '../../services/team';

const startDate = process.env.REACT_APP_START_DATE || '2020-12-21T11:02:00+01:00'

const Home = (): React.ReactElement => {
  const teamName = useSelector<RootState>(state => state.team.name);
  const [stats, setStats] = useState<FetchTeamStatsResponse>();
  const [loading, setLoading] = useState(false);
  const rallyeStart = moment(startDate);
  const now = moment();
  const hasNotStartedYet = now.diff(rallyeStart) <= 0;

  const getStats = useCallback(async () => {
    setLoading(true)
    const fetchedStats = await fetchTeamStats();
    setStats(fetchedStats);
    setLoading(false)
  }, [setStats]);

  useEffect(() => { getStats(); }, [getStats]);

  return (
    <PrivateLayout>
      <Typography variant='h5'>Équipe {teamName}</Typography>
      {hasNotStartedYet && (
        <Countdown startDate={startDate} />
      )}
      {!hasNotStartedYet && (
        <>
          <TimeProgressLine />
          <Statistics loading={loading} stats={stats} />
          {stats && <Charts stats={stats} />}
        </>
      )}
    </PrivateLayout>
  );
};

export default Home;
