// eslint-disable-next-line no-useless-escape
const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

export const createLogin = (value?: string): string | undefined => !value ? value : value.toLowerCase().replace(/[^a-z0-9]/g, '');
export const validateRequiredField = (value?: string): boolean => value !== undefined && !!value.replace(' ', '');
export const validateLogin = (value?: string): boolean => value !== undefined && value === createLogin(value);
export const validateEmail = (value?: string): boolean => value !== undefined && !!value.match(emailRegex);
export const validatePassword = (value?: string): boolean => {
  if ((value === undefined) || (value.length < 8)) return false;  // must be at least 8 characters long
  if (!value.match(/\d/)) return false; // must contain a number
  if (!value.match(/[a-z]/)) return false; // must contain a lowercase letter
  if (!value.match(/[A-Z]/)) return false; // must contain an uppercase letter
  return true;
};
