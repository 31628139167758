import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import { createPaymentIntent, sendPaymentResult } from '../../services/team';
import StripeInput from './StripeInput';
import { themeObject, bigGreenButton, form } from '../../helpers/style';
import CustomTextField from './CustomTextField';
import ConfirmationDialog from '../components/ConfirmationDialog';
import { StripeError } from '@stripe/stripe-js';

const useStyles = makeStyles(() => ({
  form,
  error: {
    color: red[800],
  },
  block: {
    marginBottom: '1rem',
  },
  formElement: {
    margin: '1rem 0',
  },
  focusVisible: {
    border: '#888 dotted 1px',
  },
  eye: {
    backgroundColor: 'red',
  },
  bigGreenButton,
}));

const theme = createMuiTheme({ ...themeObject, palette: { type: 'light' } });

const PaymentForm = (): React.ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const [isCardnumberFocused, setIsCardnumberFocused] = useState(false);
  const [isExpiryFocused, setIsExpiryFocused] = useState(false);
  const [isCvvFocused, setIsCvvFocused] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [successOpen, setSuccessOpen] = useState(false);
  const [stripeError, setStripeError] = useState<StripeError | undefined>(undefined);

  const onConfirmSuccess = (): void => {
    setSuccessOpen(false);
    history.push('/');
  };

  const fetchClientSecret = async (): Promise<void> => {
    try {
      const { clientSecret: secret } = await createPaymentIntent();
      setClientSecret(secret);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchClientSecret();
  }, []);

  const submitPayment = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    if (!stripe || !elements || !clientSecret) return;
    const cardElement = elements.getElement(CardNumberElement);
    if (cardElement) {
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: { card: cardElement },
      });
      if (error) {
        setStripeError(error);
      } else if (paymentIntent) {
        const { payment_method: paymentMethod, status, id } = paymentIntent;
        await sendPaymentResult(paymentMethod || '', status, id);
        setSuccessOpen(true);
      }
    }
  };

  const maybeGetStripError = (): React.ReactElement => {
    if (!stripeError) return <></>;

    return <Typography className={classes.error}>
      {`Une erreur s'est produite lors du paiement (${stripeError.code}) : ${stripeError.message}`}
    </Typography>;
  };

  return (
    <ThemeProvider theme={theme}>
      <Paper elevation={0} className={classes.form}>
        <form onSubmit={submitPayment}>
          <Box className={classes.block}>
            <Box display='flex' justifyContent='center'>
              <Typography variant='h6' paragraph>Paiement</Typography>
            </Box>
            <CustomTextField
              label='Numéro de carte'
              name='ccnumber'
              variant='outlined'
              className={classes.formElement}
              required
              fullWidth
              onFocus={(): void => setIsCardnumberFocused(true)}
              InputLabelProps={{ shrink: isCardnumberFocused }}
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardNumberElement,
                  options: { placeholder: isCardnumberFocused ? '1234 1234 1234 1234' : '' },
                },
              }}
            />
            <CustomTextField
              label="Date d'expiration"
              name='expiry'
              variant='outlined'
              className={classes.formElement}
              required
              fullWidth
              onFocus={(): void => setIsExpiryFocused(true)}
              InputLabelProps={{ shrink: isExpiryFocused }}
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardExpiryElement,
                  options: { placeholder: isExpiryFocused ? 'MM / YY' : '' },
                },
              }}
            />
            <CustomTextField
              label='Cryptogramme visuel'
              name='cvv'
              variant='outlined'
              className={classes.formElement}
              required
              fullWidth
              onFocus={(): void => setIsCvvFocused(true)}
              InputLabelProps={{ shrink: isCvvFocused }}
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardCvcElement,
                  options: { placeholder: isCvvFocused ? 'CVC' : '' },
                },
              }}
            />
          </Box>
          {maybeGetStripError()}
          <Button
            fullWidth={true}
            type="submit"
            variant="outlined"
            size="large"
            className={classNames(classes.bigGreenButton, classes.formElement)}
          >
            Payer 26€
          </Button>
        </form>
        <ConfirmationDialog
          open={successOpen}
          onConfirm={onConfirmSuccess}
          onClose={onConfirmSuccess}
          title='Paiement validé'
          description='Votre paiement a été effectué avec succès ! Bon rallye !'
          confirmText="C'est parti"
        />
      </Paper>
    </ThemeProvider>
  );
};

export default PaymentForm;
