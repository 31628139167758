import React, { useCallback, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { IconButton, Tooltip } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import ErrorIcon from '@material-ui/icons/Error';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { fetchFullData } from '../../services/admin';

const flattenObject = (ob: any) => {
  let toReturn = {};

  for (let i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if ((typeof ob[i]) == 'object') {
      let flatObject = flattenObject(ob[i]) as any;
      for (let x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;
        toReturn = { ...toReturn, [i + '.' + x]: flatObject[x] }
      }
    } else {
      toReturn = { ...toReturn, [i]: ob[i] }
    }
  }
  return toReturn;
};

const AdminExtracts = () => {

  const [teams, setTeams] = useState<any>();
  const [events, setEvents] = useState<any>();
  const [errors, setErrors] = useState<any>();

  const getFullData = useCallback(async () => {
    const rawTeams = await fetchFullData('teams');
    setTeams(rawTeams.map((col: any) => flattenObject(col)))
    const rawEvents = await fetchFullData('events');
    setEvents(rawEvents.map((col: any) => flattenObject(col)))
    const rawErrors = await fetchFullData('errors');
    setErrors(rawErrors.map((col: any) => flattenObject(col)))
  }, []);

  useEffect(() => {
    getFullData();
  }, [getFullData]);

  const collections = [
    { name: 'teams', title: 'Télécharger les équipes', icon: 'team', data: teams },
    { name: 'events', title: 'Télécharger les events', icon: 'eye', data: events },
    { name: 'errors', title: 'Télécharger les erreurs', icon: 'error', data: errors },
  ]

  return (
    <div>
      {collections.map(({ title, icon, data }) => data && (
        <CSVLink data={data} key={icon}>
          <Tooltip title={title} placement='top'>
            <IconButton aria-label={title} component='span'>
              {icon === 'team' && <GroupIcon />}
              {icon === 'eye' && <VisibilityIcon />}
              {icon === 'error' && <ErrorIcon />}
            </IconButton>
          </Tooltip>
        </CSVLink>
      ))}
    </div>
  )
}

export default AdminExtracts;