import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

export interface ConfirmationDialogProps {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
  onCancel?: () => void;
  title: string;
  description: string;
  confirmText: string;
  cancelText?: string;
  confirmButtonVariant?: 'text' | 'outlined' | 'contained'
}

const ConfirmationDialog = ({ onClose, onConfirm, onCancel, open, title, description, confirmText, cancelText, confirmButtonVariant = 'text' }: ConfirmationDialogProps) => (
  <Dialog
    onClose={onClose}
    aria-labelledby="dialog-title"
    open={open}
  >
    <DialogTitle id="dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
    </DialogContent>
    <DialogActions>
      {onCancel && cancelText && <Button onClick={onCancel}>{cancelText}</Button>}
      <Button onClick={onConfirm} color="primary" autoFocus variant={confirmButtonVariant}>{confirmText}</Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmationDialog;
