import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { AppBar, Box, Button, Container, makeStyles } from '@material-ui/core';
import { getIsLoggedIn, removeToken } from '../../helpers/auth';

const useStyles = makeStyles(() => ({
  appBar: {
    boxShadow: 'none',
    padding: '1rem 0',
  },
  link: {
    marginLeft: '2rem',
  },
}));

const disabledRegistration = process.env.REACT_APP_REGISTRATION_CLOSED === 'true'

const Header = (): React.ReactElement => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { pathname } = location;
  const isLoggedIn = getIsLoggedIn();

  const goToSignin = (): void => history.push('/signin');
  const goToSignup = (): void => history.push('/signup');
  
  const logout = (): void => {
    removeToken();
    history.push('/signin');
  };

  return (
    <AppBar position='relative' color='transparent' className={classes.appBar}>
      <Container maxWidth='lg'>
        <Box display='flex' alignItems='center' justifyContent='flex-end'>
          {!isLoggedIn && pathname !== '/signin' && (
            <Box className={classes.link}>
              <Button onClick={goToSignin} variant='outlined'>Se connecter</Button>
            </Box>
          )}
          {!isLoggedIn && pathname !== '/signup' && !disabledRegistration && (
            <Box className={classes.link}>
              <Button onClick={goToSignup} variant='outlined'>S&apos;inscrire</Button>
            </Box>
          )}
          {isLoggedIn && (
            <Box className={classes.link}>
              <Button onClick={logout} variant='outlined'>Se deconnecter</Button>
            </Box>
          )}
        </Box>
      </Container>
    </AppBar>
  );
};

export default Header;
