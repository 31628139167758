import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PublicLayout from '../components/PublicLayout';
import ForgottenPasswordForm from '../forms/ForgottenPasswordForm';

const useStyles = makeStyles(() => ({
  container: {
    paddingBottom: '3rem',
    paddingTop: '3rem',
  },
}));

const ForgottenPassword = () => {
  const classes = useStyles();

  return (
    <PublicLayout>
      <Container maxWidth='md' className={classes.container}>
        <Typography variant='h1' gutterBottom>Mot de passe oublié</Typography>
        <Typography paragraph variant='subtitle1' color='textSecondary'>
          Vous allez recevoir un e-mail avec le lien de modification du mot de passe.
        </Typography>
        <Container maxWidth='sm' className={classes.container}>
          <ForgottenPasswordForm />
        </Container>
        <Typography align='center' variant='body2' color='textSecondary'>
          {`Vous ne recevez pas l'e-mail ? Contactez-nous à l'adresse `}
          <a href="mailto:rallye.hiver.2021@gmail.com">rallye.hiver.2021@gmail.com</a>
        </Typography>
      </Container>
    </PublicLayout>
  )
}

export default ForgottenPassword;