import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import useDimensions from '../../hooks/useDimensions';

type FlashLightProps = {
  children: ReactElement;
}

type StyleProps = {
  x: number;
  y: number;
  height?: number;
  width?: number;
}

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    minHeight: '100vh',
  },
  light: ({ x = 0, y = 0, height = 0, width = 0 }: StyleProps) => ({
    position: 'absolute',
    minWidth: '100vw',
    minHeight: '100vh',
    width: '100%',
    left: 0,
    top: 0,
    background: `radial-gradient(circle ${width / 8}px at ${x}px ${y}px,rgba(0,0,0,0) 0%,rgba(0,0,0,.5) 60%,rgba(0,0,0,1) 100%)`,
    transition: 'none',
    willChange: 'transform',
    cursor: 'none',
    height: height ? `${height + 32}px` : '100%',
    zIndex: 1,
  }),
}));

const Flashlight = ({ children }: FlashLightProps): React.ReactElement => {
  const [ref, { height, width }] = useDimensions();
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [scrollPosition, setScrollPostion] = useState({ x: 0, y: 0 });
  const classes = useStyles({
    x: cursorPosition.x + scrollPosition.x,
    y: cursorPosition.y + scrollPosition.y,
    height,
    width,
  });

  const update = useCallback((x: number, y: number): void => { setCursorPosition({ x, y }); }, []);
  const updateMouse = useCallback((e: MouseEvent): void => { update(e.clientX, e.clientY); }, [update]);
  const updateTouch = useCallback((e: TouchEvent): void => { update(e.touches[0].clientX, e.touches[0].clientY); }, [update]);
  const updateScroll = useCallback((): void => { setScrollPostion({ x: window.scrollX, y: window.scrollY }); }, []);

  useEffect(() => {
    document.addEventListener('mousemove', updateMouse);
    document.addEventListener('touchmove', updateTouch);
    window.addEventListener('scroll', updateScroll);
    return (): void => {
      document.addEventListener('mousemove', updateMouse);
      document.addEventListener('touchmove', updateTouch);
      window.addEventListener('scroll', updateScroll);
    };
  }, [updateMouse, updateTouch, updateScroll]);

  useEffect(() => {
    setTimeout(() => { update(1, 1); }, 200)
  }, [update]);

  return (
    <div ref={ref} className={classes.container}>
      <div className={classes.light} />
      {children}
    </div>
  );
};

export default Flashlight;
