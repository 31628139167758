import moment from 'moment';
import { FetchTeamStatsResponse } from '../services/team';
import { SolvedRiddles } from '../types/riddles';
import { range } from './common';

const startDate = process.env.REACT_APP_START_DATE || '2020-12-21T11:02:00+01:00'
const endDate = process.env.REACT_APP_END_DATE || '2021-03-20T23:59:59+01:00'

export type AnswerDataType = {
  date: string;
  solved: number;
  done: number;
};

type MemoType = {
  numberOfSolvedRiddles: number;
  numberOfDoneQuests: number;
  answersData: AnswerDataType[];
};

type GetChartDataPropsType = {
  stats: FetchTeamStatsResponse
}

const getSolvedByDate = (solvedTasks: SolvedRiddles) => {
  const nbSolvedTasksByDate = new Map();
  Object.values(solvedTasks).forEach(solvedOn => {
    const formattedDate = moment(solvedOn).format('YYYY-MM-DD');
    nbSolvedTasksByDate.set(
      formattedDate,
      (nbSolvedTasksByDate.get(formattedDate) || 0) + 1,
    );
  });
  return nbSolvedTasksByDate
}


export const getChartData = ({ stats }: GetChartDataPropsType) => {
  const rallyeStart = moment(startDate);
  const rallyeEnd = moment(endDate);
  const now = moment();
  const totalDaysCount = rallyeEnd.diff(rallyeStart, 'days') + 1;
  const elapsedDaysCount = Math.min(Math.max(now.diff(rallyeStart, 'days') + 1, 0), totalDaysCount);
  const nbSolvedRiddlesByDate = getSolvedByDate(stats.solvedRiddles)
  const nbDoneQuestsByDate = getSolvedByDate(stats.questsDone)

  const data = range(elapsedDaysCount).reduce((memo: MemoType, i) => {
    const dateMoment = rallyeStart.clone().add(i, 'days');
    const formattedDate = moment(dateMoment).format('YYYY-MM-DD');
    const nbSolvedRiddlesThisDay = nbSolvedRiddlesByDate.get(formattedDate) || 0;
    const nbDoneQuestsThisDay = nbDoneQuestsByDate.get(formattedDate) || 0;
    const cumulatedSolved = memo.numberOfSolvedRiddles + nbSolvedRiddlesThisDay;
    const cumulatedDone = memo.numberOfDoneQuests + nbDoneQuestsThisDay;

    return {
      numberOfSolvedRiddles: cumulatedSolved,
      numberOfDoneQuests: cumulatedDone,
      answersData: [...memo.answersData, { date: dateMoment.format('D MMM'), solved: cumulatedSolved, done: cumulatedDone }],
    };
  }, { numberOfSolvedRiddles: 0, numberOfDoneQuests: 0, answersData: [] });
  return data.answersData
}