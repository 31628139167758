import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  regular: {
    height: 'auto',
    width: '200px',
    alignSelf: 'flex-start',
  },
}));


const Logo = ({ className = '' }): React.ReactElement => {
  const classes = useStyles();
  return (
    <img
      className={classNames(className, classes.regular)}
      src={'https://d2n81j17dx4v5l.cloudfront.net/logo.png'}
      alt='Rallye Hiver 2021'
    />
  );
};

export default Logo;
