import React from 'react';
import { match, useRouteMatch, Redirect } from 'react-router-dom';
import PrivateLayout from '../components/PrivateLayout';
import Riddle from '../components/Riddle';
import Quest from '../components/Quest';

const Task = (): React.ReactElement => {
  const routeMatch: match<{ type: string }> = useRouteMatch();
  const taskType = routeMatch.params ? routeMatch.params.type : null;

  if (taskType !== 'riddles' && taskType !== 'quests') {
    return <Redirect to='/404' />;
  }
  return (
    <PrivateLayout>
      {taskType === 'riddles' ? <Riddle /> : <Quest />}
    </PrivateLayout>
  );
};

export default Task;
