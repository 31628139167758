import { PaletteType, ThemeOptions, colors } from '@material-ui/core';

export const bigGreenButton = {
  minHeight: '65px',
  backgroundColor: colors.teal[800],
  color: 'white',
  '&:hover': {
    backgroundColor: colors.teal[900],
  },
};

export const form = {
  padding: '24px',
  borderRadius: '6px',
  color: '#586069',
  backgroundColor: '#fafbfc',
};

export const greenLabel = {
  color: 'green',
};

export const themeObject: ThemeOptions = {
  palette: {
    type: 'dark' as PaletteType,
    primary: {
      main: colors.teal[800],
    },
    secondary: {
      main: colors.lime[800],
    },
  },
  typography: {
    fontFamily: 'Fondamento, cursive',
    fontSize: 16,
    h1: {
      fontSize: '4rem',
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: '1.25rem',
    },
    caption: {
      fontSize: '0.75rem',
    },
    // h1
    // h2
    // h3
    // h4
    // h5
    // h6
    // subtitle1
    // subtitle2
    // body1
    // body2
    // button
    // caption
    // overline
  },
};

export const formComponentSharedCss = {
  borderRadius: '4px',
  padding: '10px',
  width: '100%',
  minWidth: '120px',
};
