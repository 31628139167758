import React, { useCallback, useState, ReactElement } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { makeStyles, colors, Button, CircularProgress } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import { submitRiddleAnswer, startRiddle } from '../../services/riddle';
import { setRiddle, setRiddleSolved } from '../../store/actions';
import { SolvedOn } from '../../types/puzzles';
import { formatDuration, getFormattedDateTime } from '../../helpers/common';
import ConfirmationDialog from './ConfirmationDialog';

interface RiddleHeaderProps {
  puzzleId: string;
  solvedOn?: SolvedOn;
  timed: boolean;
  startDate?: string;
  step1Date?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    marginRight: theme.spacing(2),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  solvedCommon: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    borderRadius: '4px',
    cursor: 'default',
  },
  notSolved: {
    backgroundColor: colors.red[800],
    margin: '0rem 1rem'
  },
  solved: {
    backgroundColor: colors.teal[800],
  }
}));

const RiddleHeader = ({ puzzleId, solvedOn, timed, startDate, step1Date }: RiddleHeaderProps): ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [answer, setAnswer] = useState('');
  const [goodAnswerFound, setGoodAnswerFound] = useState(false);
  const [wrongAnswerMessage, setWrongAnswerMessage] = useState<string>();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const submitAnswer = useCallback(async (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (!answer) { return; }
    const response = await submitRiddleAnswer({ riddleId: puzzleId, answer, ...wrongAnswerMessage && { failMessage: wrongAnswerMessage } });
    setAnswer('')
    if (response.solvedOn) {
      setGoodAnswerFound(true);
      dispatch(setRiddleSolved(puzzleId, response.solvedOn));
      setWrongAnswerMessage(undefined)
    } else if (response.failMessage) {
      setWrongAnswerMessage(response.failMessage);
    } else {
      setWrongAnswerMessage('Mauvaise réponse, cherchez encore !')
    }
  }, [dispatch, puzzleId, answer, wrongAnswerMessage]);

  const startTimedRiddle = useCallback(async () => {
    setLoading(true)
    const newRiddle = await startRiddle(puzzleId);
    dispatch(setRiddle(newRiddle));
    setLoading(false)
  }, [dispatch, puzzleId]);

  const handleAnswerChange = ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>): void => setAnswer(value);

  const onCloseConfirmation = (): void => { setConfirmationOpen(false) }
  const onOpenConfirmation = (): void => { setConfirmationOpen(true) }

  const duration = timed && startDate && solvedOn && moment.duration(moment(solvedOn).diff(moment(startDate))).asMilliseconds()
  const riddleDuration = duration ? formatDuration(duration) : ''

  if (loading) {
    return <CircularProgress color='inherit' />
  }

  if (timed && !startDate) {
    return (
      <>
        <Button variant='contained' onClick={onOpenConfirmation}>Commencer l'énigme contre la montre</Button>
        <ConfirmationDialog
          open={confirmationOpen}
          onClose={onCloseConfirmation}
          onConfirm={startTimedRiddle}
          onCancel={onCloseConfirmation}
          title="Voulez-vous commencer l'énigme contre la montre ?"
          description='Le nombre de points que vous gagnerez avec cette énigme dépendra de votre rapidité à la résoudre'
          confirmText="C'est parti"
          cancelText='Non je commencerai plus tard'
          confirmButtonVariant='contained'
        />
      </>
    )
  }

  if (timed && startDate && !step1Date) return <div />;

  if (goodAnswerFound) {
    return (
      <div className={classNames(classes.solvedCommon, classes.solved)}>Bravo !{riddleDuration ? ` Énigme résolue en ${riddleDuration}` : ''}</div>
    );
  }

  return (
    <>
      <Paper component='form' className={classes.root}>
        <InputBase
          className={classes.input}
          placeholder='Votre réponse'
          autoCapitalize='off'
          autoComplete='off'
          autoCorrect='off'
          name='answer'
          type='text'
          inputProps={{ 'aria-label': 'Votre réponse', maxLength: 30 }}
          value={answer}
          onChange={handleAnswerChange}
        />
        <IconButton type='submit' onClick={submitAnswer} className={classes.iconButton} aria-label='search'>
          <ArrowForwardIcon />
        </IconButton>
      </Paper>
      {wrongAnswerMessage && (
        <div className={classNames(classes.solvedCommon, classes.notSolved)}>{wrongAnswerMessage}</div>
      )}
      {!wrongAnswerMessage && solvedOn && (
        <div className={classNames(classes.solvedCommon, classes.solved)}>Énigme résolue ! Le {getFormattedDateTime(solvedOn)}{riddleDuration ? ` en ${riddleDuration}` : ''}.</div>
      )}
    </>
  );
}

export default RiddleHeader;
