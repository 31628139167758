import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, colors } from '@material-ui/core';
import { AreaChart, Area, ReferenceLine, XAxis, YAxis, Tooltip, TooltipFormatter, Legend, LegendValueFormatter } from 'recharts';
import { FetchTeamStatsResponse } from '../../services/team';
import useDimensions from '../../hooks/useDimensions';
import { Riddle as RiddleType } from '../../types/riddles';
import { AnswerDataType, getChartData } from '../../helpers/stats';
import { RootState } from '../../store';
import 'moment/locale/fr';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(4)}px 0`,
  }
}));

type ChartsPropsType = {
  stats: FetchTeamStatsResponse
}

const Charts = ({ stats }: ChartsPropsType): React.ReactElement => {
  const classes = useStyles();
  const [ref, { width }] = useDimensions();
  const riddles = useSelector<RootState>(state => state.riddles) as RiddleType[];
  const [answersData, setAnswersData] = useState<AnswerDataType[]>();

  const getAnswersStats = useCallback(() => {
    if (stats && riddles) {
      const data = getChartData({ stats })
      setAnswersData(data);
    }
  }, [riddles, stats]);

  useEffect(() => { getAnswersStats(); }, [getAnswersStats]);

  const getLabelText = (label: string) => label === 'solved' ? 'Énigmes résolues' : 'Parcours effectués'
  const tooltipFormatter: TooltipFormatter = (value, name) => [value, getLabelText(name)];
  const legendFormatter: LegendValueFormatter = (value) => getLabelText(value)

  if (!answersData || answersData.length === 0) {
    return <></>;
  }

  const margin = { top: 10, right: 30, left: 0, bottom: 0 }
  return (
    <div className={classes.container} ref={ref}>
      <AreaChart width={width} height={450} data={answersData} margin={margin}>
        <XAxis dataKey='date' />
        <YAxis domain={[0, riddles.length + 1]} ticks={[5, 10, 15, 20]} />
        <Tooltip labelStyle={{ color: '#586069' }} formatter={tooltipFormatter} />
        <Legend formatter={legendFormatter} iconType='square' />
        <ReferenceLine y={riddles.length} stroke={colors.teal[900]} />
        <Area type='monotone' dataKey='solved' stroke={colors.teal[800]} fill={colors.teal[800]} />
        <Area type='monotone' dataKey='done' stroke={colors.lime[800]} fill={colors.lime[800]} />
      </AreaChart>
    </div>
  );
};

export default Charts;
