import React from 'react';
import { createMuiTheme, Container, Grid, makeStyles, ThemeProvider, Typography } from '@material-ui/core';
// import SearchIcon from '@material-ui/icons/Search';
// import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
// import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import GroupIcon from '@material-ui/icons/Group';
import RoomIcon from '@material-ui/icons/Room';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import { themeObject } from '../../helpers/style';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: '2rem 0',
  },
  block: {
    textAlign: 'center',
    padding: '40px',
  },
  separator: {
    borderTop: 'solid 1px black'
  },
  underlined: {
    textDecoration: 'underline'
  }
}));

const theme = createMuiTheme({
  ...themeObject,
  palette: { type: 'light' },
});

const Info = (): React.ReactElement => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.container}>
        <Container maxWidth='lg'>
          <Grid alignItems='center' container>
            <Grid item md={4} sm={12} className={classes.block}>
              <GroupIcon fontSize='large' color='action' />
              <Typography variant='subtitle1'>Par équipes de 4-8 personnes, vous avez les trois mois d’hiver pour résoudre une vingtaine d’énigmes. Voilà de quoi occuper vos soirées d’hiver confinées...</Typography>
            </Grid>
            <Grid item md={4} sm={12} className={classes.block}>
              <RoomIcon fontSize='large' color='action' />
              <Typography variant='subtitle1'>Ces énigmes vous permettent d’accéder à des parcours qui traversent des lieux en lien avec le thème de ce rallye. C’est l’occasion de se cultiver en s’amusant les weekends brumeux !</Typography>
            </Grid>
            <Grid item md={4} sm={12} className={classes.block}>
              <EmojiEventsIcon fontSize='large' color='action' />
              <Typography variant='subtitle1'>A la clôture du rallye, tous les participants sont invités à partager les moments qui ont rythmé l’hiver passé, lors d’une remise des prix et du titre tant convoité...</Typography>
            </Grid>
          </Grid>
          <Grid alignItems='center' container className={classes.separator}>
            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.block}>
              <a href='https://archives.rallyehiver.fr' target='_blank' rel='noopener noreferrer'>
              <Typography variant='subtitle1' className={classes.underlined}>Pour accéder aux anciens rallyes, c'est par ici !</Typography>
              </a>
            </Grid>
          </Grid>
        </Container>
      </div>
    </ThemeProvider>
  );
};

export default Info;
