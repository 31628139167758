import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch, Redirect, match } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PuzzleContent from './PuzzleContent';
import { RootState } from '../../store';
import { Riddle as RiddleType } from '../../types/riddles';
import LoadingArea from './LoadingArea';
import { setRiddle } from '../../store/actions';
import { fetchRiddleStep } from '../../services/riddle';


const Riddle = (): React.ReactElement => {
  const dispatch = useDispatch();
  const routeMatch: match<{ id: string; step?: string }> = useRouteMatch();
  const taskStep = routeMatch.params ? routeMatch.params.step : null;
  const riddleId = routeMatch.params ? routeMatch.params.id : null;
  const riddles = useSelector<RootState>(state => state.riddles) as RiddleType[];
  const riddle = riddles ? riddles.find(riddle => riddle.id === riddleId) : null;
  const [loading, setLoading] = useState(false);

  const getRiddleStep = useCallback(async () => {
    if (riddleId && taskStep) {
      setLoading(true);
      const newRiddle = await fetchRiddleStep(riddleId, taskStep);
      if (newRiddle) {
        dispatch(setRiddle(newRiddle));
      }
      setLoading(false);
    }
  }, [dispatch, riddleId, taskStep]);

  useEffect(() => {
    if (taskStep && !(riddle || {}).step1Date) {
      getRiddleStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riddleId, taskStep, getRiddleStep]);

  if (riddles.length === 0 || loading) {
    return <LoadingArea />;
  }
  if (!riddle) {
    return <Redirect to='/404' />;
  }
  return (
    <PuzzleContent
      key={riddle.id}
      puzzleId={riddle.id}
      solvedOn={riddle.solvedOn}
      pdfURL={riddle.pdfURL}
      pdfPrintURL={riddle.pdfPrintURL}
      audioFile={riddle.audioFile}
      backgroundAudioFile={riddle.backgroundAudioFile}
      timed={riddle.timed}
      startDate={riddle.startDate}
      gifURL={riddle.gifURL}
      videoURL={riddle.videoURL}
      step1Date={riddle.step1Date}
      puzzleType="riddle"
    />
  );
};

export default Riddle;
