import React from 'react';
import { Box, Container, makeStyles, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  banner: {
    paddingTop: '12rem',
    paddingBottom: '36rem',
  },
}));

const LoadingArea = (): React.ReactElement => {
  const classes = useStyles();
  return (
    <Container maxWidth='sm' className={classes.banner}>
      <Box display='flex' alignItems='center' flexDirection='column'>
        <CircularProgress color='inherit' />
      </Box>
    </Container>
  );
};

export default LoadingArea;
