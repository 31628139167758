import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Redirect, useLocation, RouteProps, useHistory } from 'react-router-dom';
import { getIsLoggedIn, removeToken } from '../../helpers/auth';
import { Team } from '../../types/team';
import { fetchMyTeam } from '../../services/team';
import Loading from '../pages/Loading';
import { setTeamName, setTeamHasPaid, setRiddlesSolved, setQuestsDone } from '../../store/actions';

interface PrivateRouteInternalProps {
  component: () => React.ReactElement;
}

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteInternalProps & RouteProps): React.ReactElement => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [team, setTeam] = useState<Team>();
  const shouldBeAdmin = location.pathname === '/admin';
  const isLoggedIn = getIsLoggedIn(shouldBeAdmin);
  const paymentPath = '/payment';

  const fetchTeam = useCallback(async () => {
    if (isLoggedIn) {
      //  || location.pathname === '/'
      try {
        const currentTeam = await fetchMyTeam();
        const { name, hasPaid, solvedRiddles, questsDone } = currentTeam;
        dispatch(setTeamName(name));
        dispatch(setTeamHasPaid(hasPaid));
        dispatch(setQuestsDone(questsDone));
        dispatch(setRiddlesSolved(solvedRiddles));
        setTeam({ name, hasPaid });
      } catch (e) {
        removeToken();
        history.push('/signin');
      }
    }
  }, [isLoggedIn, dispatch, location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchTeam();
  }, [fetchTeam]);

  if (!isLoggedIn) {
    if (!shouldBeAdmin) {
      return (
        <Route {...rest} render={(props): React.ReactElement => (
          <Redirect to={{ pathname: '/signin', state: { from: props.location } }} />
        )} />
      );
    } else {
      return (
        <Route {...rest} render={(): React.ReactElement => <Redirect to={{ pathname: '/admin/signin' }} />} />
      );
    }
  }
  if (isLoggedIn && shouldBeAdmin) {
    return (
      <Route {...rest} render={(props): React.ReactElement => <Component {...props} {...rest} />} />
    );
  }
  if (team) {
    if (!team.hasPaid && location.pathname !== paymentPath) {
      return <Route {...rest} render={(): React.ReactElement => <Redirect to={{ pathname: paymentPath }} />} />;
    }
    if (team.hasPaid && location.pathname === paymentPath) {
      return <Route {...rest} render={(): React.ReactElement => <Redirect to={{ pathname: '/' }} />} />;
    }
    return <Route {...rest} render={(props): React.ReactElement => <Component {...props} {...rest} />} />;
  }
  return <Loading />;
};

export default PrivateRoute;
