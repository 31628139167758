import jwtDecode from 'jwt-decode';

export const decodeToken = (token: string): any => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken;
  } catch (e) {
    return null;
  }
};

export const getToken = (): string | null => localStorage.getItem('token');
export const setToken = (token: string): void => localStorage.setItem('token', token);
export const removeToken = (): void => localStorage.removeItem('token');

export const getIsLoggedIn = (admin = false): boolean => {
  const token = getToken();
  const decodedJWT = token && decodeToken(token);
  if (admin) {
    return !!(decodedJWT && decodedJWT.isAdmin);
  }
  return !!decodedJWT;
};
