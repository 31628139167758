import classNames from 'classnames';
import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DoneIcon from '@material-ui/icons/Done';
import EmailIcon from '@material-ui/icons/Email';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import Button from '@material-ui/core/Button';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MapIcon from '@material-ui/icons/Map';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Logo from './Logo';
import { match, useHistory, useRouteMatch, Link } from 'react-router-dom';
import { removeToken } from '../../helpers/auth';
import { Quest } from '../../types/quests';
import { setRiddles, setQuests } from '../../store/actions';
import { Riddle } from '../../types/riddles';
import { RootState } from '../../store';
import { loadQuests } from '../../services/quests';
import { loadRiddles } from '../../services/riddle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    doneIcon: {
      minWidth: '28px',
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    logo: {
      marginTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      width: '100%',
    },
    logoutContainer: {
      width: '100%',
      marginBottom: '1rem',
      marginTop: '1rem',
    },
    logout: {
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    iconWithContentContainer: {
      padding: `0 ${theme.spacing(1)}px`,
      display: 'flex',
      alignItems: 'center',
      minHeight: '55px',
      '& > div': {
        fontSize: '0.9em',
        lineHeight: 1.2,
        paddingLeft: theme.spacing(1),
        width: '100%',
      },
    },
    solved: {
      opacity: 0.5,
    },
  }),
);

const CustomLink = React.forwardRef<HTMLButtonElement, any>(({ to, ...props }, ref) => <Link to={to} {...props} ref={ref} />);

const DrawerContent = (): React.ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const routeMatch: match<{ id: string, type: string }> = useRouteMatch();
  const taskType = (routeMatch.params && routeMatch.params.type) || 'dashboard';
  const [selectedItem, setSelectedItem] = useState(taskType);
  const riddles = useSelector<RootState>(state => state.riddles) as Riddle[];
  const quests = useSelector<RootState>(state => state.quests) as Quest[];

  const startDate = process.env.REACT_APP_START_DATE || '2020-12-21T11:02:00+01:00'
  const rallyeStart = moment(startDate);
  const now = moment();
  const hasNotStartedYet = now.diff(rallyeStart) <= 0;

  const logout = (): void => {
    removeToken();
    history.push('/signin');
  };

  const fetchRiddles = useCallback(async () => {
    const loadedRiddles = await loadRiddles();
    dispatch(setRiddles(loadedRiddles));
  }, [dispatch]);

  const fetchQuests = useCallback(async () => {
    const loadedQuests = await loadQuests();
    dispatch(setQuests(loadedQuests));
  }, [dispatch]);

  useEffect(() => {
    fetchRiddles();
    fetchQuests();
  }, [fetchRiddles, fetchQuests]);


  const isRiddlesSelected = selectedItem === 'riddles';
  const isQuestsSelected = selectedItem === 'quests';
  const selectedPuzzleId = routeMatch.params ? routeMatch.params.id : undefined;

  return (
    <Scrollbars autoHide>
      <Box display='flex' justifyContent='center'>
        <Logo className={classes.logo} />
      </Box>
      <List
        component='nav'
        aria-labelledby='nested-list-subheader'
        className={classes.root}
      >
        <ListItem
          button
          component={CustomLink}
          to='/'
          selected={selectedItem === 'dashboard'}
          onClick={(): void => setSelectedItem(selectedItem === 'dashboard' ? '' : 'dashboard')}
        >
          <ListItemText primary='Tableau de bord' />
        </ListItem>
        {riddles && riddles.length ? (
          <ListItem
            button
            selected={isRiddlesSelected}
            onClick={(): void => setSelectedItem(isRiddlesSelected ? '' : 'riddles')}
          >
            <ListItemText primary='Énigmes' />
            {isRiddlesSelected ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        ) : null}
        <Collapse in={isRiddlesSelected} timeout='auto' unmountOnExit>
          <List dense component='div' disablePadding>
            {riddles && riddles.length ? riddles.map(({ id, name, solvedOn }) => (
              <ListItem
                key={id}
                button
                className={classes.nested}
                component={CustomLink}
                to={`/tasks/riddles/${id}`}
                selected={isRiddlesSelected && (id === selectedPuzzleId)}
              >
                <ListItemText className={classNames({ [classes.solved]: !!solvedOn })}>{name}</ListItemText>
                {solvedOn && (
                  <ListItemIcon className={classes.doneIcon}>
                    <DoneIcon color='primary' />
                  </ListItemIcon>
                )}
              </ListItem>
            )) : null}
          </List>
        </Collapse>
        {quests && quests.length ? (
          <ListItem
            button
            selected={isQuestsSelected}
            onClick={(): void => setSelectedItem(isQuestsSelected ? '' : 'quests')}
          >
            <ListItemText primary='Parcours' />
            {isQuestsSelected ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        ) : null}
        <Collapse in={isQuestsSelected} timeout='auto' unmountOnExit>
          <List dense component='div' disablePadding>
            {quests && quests.length ? quests.map(({ id, name, solvedOn }) => (
              <ListItem
                key={id}
                button
                className={classes.nested}
                component={CustomLink}
                to={`/tasks/quests/${id}`}
                selected={isQuestsSelected && (id === selectedPuzzleId)}
              >
                <ListItemText className={classNames({ [classes.solved]: !!solvedOn })}>{name}</ListItemText>
                {solvedOn && (
                  <ListItemIcon className={classes.doneIcon}>
                    <DoneIcon color='primary' />
                  </ListItemIcon>
                )}
              </ListItem>
            )) : null}
          </List>
        </Collapse>
      </List>
      <Divider />
      <div>
        {!hasNotStartedYet && (
          <Link to='/map'>
            <div className={classes.iconWithContentContainer}>
              <MapIcon color='primary' fontSize='large' />
              <div>Plan des parcours</div>
            </div>
          </Link>
        )}
        <Link to='/rules'>
          <div className={classes.iconWithContentContainer}>
            <ListAltIcon color='primary' fontSize='large' />
            <div>Règles du jeu</div>
          </div>
        </Link>
        <a href='https://archives.rallyehiver.fr' target='_blank' rel='noopener noreferrer'>
          <div className={classes.iconWithContentContainer}>
            <MenuBookIcon color='primary' fontSize='large' />
            <div>Anciens rallyes</div>
          </div>
        </a>
        <a href='mailto:rallye.hiver.2021@gmail.com'>
          <div className={classes.iconWithContentContainer}>
            <EmailIcon color='primary' fontSize='large' />
            <div>Contacter les organisateurs</div>
          </div>
        </a>
      </div>
      <Divider />
      <Box display='flex' justifyContent='center' className={classes.logoutContainer}>
        <Button size='small' fullWidth onClick={logout} className={classes.logout}>Se déconnecter</Button>
      </Box>
    </Scrollbars>
  );
};

export default DrawerContent;
