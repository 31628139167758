import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import useDimensions from '../../hooks/useDimensions';

interface TimedRiddleProps {
  gifURL: string;
}

type StyleProps = {
  height: number,
  width: number,
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    paddingTop: theme.spacing(8),
    cursor: 'none',
  },
  overlay: {
    position: 'absolute',
    minWidth: '100vw',
    minHeight: '100vh',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    background: 'black'
  },
  gif: ({ height = 1, width = 0 }: StyleProps) => ({
    ...height >= width ? {
      width: '100%',
    } : {
      height: '100%'
    }
  }),
  gifContainer: ({ height = 1, width = 0 }: StyleProps) => ({
    ...height >= width ? {
      width: '95%',
      maxWidth: '600px',
    } : {
        height: '95%',
        maxHeight: '600px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
      },
    position: 'relative',
  })
}));

const TimedRiddle = ({ gifURL }: TimedRiddleProps) => {
  const [reload, setReload] = useState(false)
  const [ref, { height, width }] = useDimensions({ reload });
  console.log({ height, width })
  const classes = useStyles({ height, width })

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  }

  const onLoad = () => { setReload(!reload); }

  useEffect(() => {
    document.addEventListener('contextmenu', handleClick);
    return () => {
      document.removeEventListener('contextmenu', handleClick);
    }
  }, []);

  return (
    <div className={classes.container} ref={ref}>
      <div className={classes.overlay} />
      <div className={classes.gifContainer}>
        <img className={classes.gif} src={gifURL} onLoad={onLoad} alt='gif contre la montre' />
      </div>
    </div>
  )
}

export default TimedRiddle;