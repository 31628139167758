import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFDocumentProxy } from 'pdfjs-dist';
import useDimensions from '../../hooks/useDimensions';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type PdfDocumentProps = {
  className?: string,
  url: string,
  onLoadSuccess?: () => void
}

const PdfDocument = ({ className, url, onLoadSuccess }: PdfDocumentProps): React.ReactElement => {
  const [ref, { width: PDFWidth }] = useDimensions();
  const [numPages, setNumPages] = useState<number>();

  const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy): void => {
    setNumPages(numPages);
    if (onLoadSuccess) {
      onLoadSuccess()
    }
  };

  return (
    <div ref={ref} className={className}>
      <Document file={url} onLoadError={console.error} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} width={PDFWidth} renderAnnotationLayer={false} />
        ))}
      </Document>
    </div>
  );
};

export default PdfDocument;
