import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PublicLayout from '../components/PublicLayout';
import LoginForm from '../forms/LoginForm';
import { getIsLoggedIn } from '../../helpers/auth';

const useStyles = makeStyles(() => ({
  container: {
    paddingBottom: '3rem',
    paddingTop: '3rem',
  },
}));

const AdminSignin = () => {
  const classes = useStyles();
  const history = useHistory();
  const isLoggedIn = getIsLoggedIn(true);

  const redirectAfterLogin = useCallback(() => {
    history.push('/admin');
  }, [history]);

  useEffect(() => {
    if (isLoggedIn) {
      redirectAfterLogin();
    }
  }, [redirectAfterLogin, isLoggedIn]);
  return (
    <PublicLayout>
      <Container maxWidth='md' className={classes.container}>
        <Typography variant='h1' gutterBottom>Connexion Admin</Typography>
        <Container maxWidth='sm' className={classes.container}>
          <LoginForm redirectAfterLogin={redirectAfterLogin} />
        </Container>
      </Container>
    </PublicLayout>
  )
}

export default AdminSignin;