import { parseOrThrowError } from '../helpers/request';
import { getToken } from '../helpers/auth';
import { AdminTeam } from '../types/team';
import { Events } from '../types/events';
import { Errors } from '../types/errors';

const API_URI = process.env.REACT_APP_API_URI;

type FetchTeamsResponse = AdminTeam[];

export const fetchTeams = async (): Promise<FetchTeamsResponse> => {
  const token = getToken();
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);

  const response = await fetch(`${API_URI}/admin/teams`, {
    method: 'GET',
    headers,
    cache: 'no-store',
  });
  return parseOrThrowError<FetchTeamsResponse>(response);
};

type FetchEventsResponse = Events[];

export const fetchEvents = async (): Promise<FetchEventsResponse> => {
  const token = getToken();
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);

  const response = await fetch(`${API_URI}/admin/events`, {
    method: 'GET',
    headers,
    cache: 'no-store',
  });
  return parseOrThrowError<FetchEventsResponse>(response);
};

type FetchErrorsResponse = Errors[];

export const fetchErrors = async (): Promise<FetchErrorsResponse> => {
  const token = getToken();
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);

  const response = await fetch(`${API_URI}/admin/errors`, {
    method: 'GET',
    headers,
    cache: 'no-store',
  });
  return parseOrThrowError<FetchErrorsResponse>(response);
};

export const fetchFullData = async (type: string): Promise<any> => {
  const token = getToken();
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);

  const response = await fetch(`${API_URI}/admin/${type}/full`, {
    method: 'GET',
    headers,
    cache: 'no-store',
  });
  return parseOrThrowError<any>(response);
}