import React, { useRef, useImperativeHandle } from 'react'

const StripeInput = ({ component: Component, inputRef, ...props }: any) => {
  const elementRef = useRef();
  useImperativeHandle(inputRef, () => ({
    focus: () => { if (elementRef?.current) return (elementRef.current || { focus: null }).focus }
  }));

  return (
    <Component
      onReady={(element: any) => (elementRef.current = element)}
      {...props}
    />
  );
}

export default StripeInput;