import {
  SET_TEAM_NAME,
  SET_RIDDLES,
  SET_RIDDLE_SOLVED,
  SET_RIDDLES_SOLVED,
  SET_RIDDLE,
  SET_QUEST_DONE,
  SET_QUESTS_DONE,
  SET_QUESTS,
  SET_TEAM_HAS_PAID,
} from './actionTypes';
import { SolvedOn } from '../types/puzzles';
import { Riddle, RiddleIdType, SolvedRiddles } from '../types/riddles';
import { Quest, QuestIdType, QuestsDone } from '../types/quests';

// team

type SetTeamNameAction = {
  type: typeof SET_TEAM_NAME;
  payload: string;
};

type SetTeamHasPaidAction = {
  type: typeof SET_TEAM_HAS_PAID;
  payload: boolean;
};

export type TeamAction = SetTeamNameAction | SetTeamHasPaidAction;

export const setTeamName = (name: string): SetTeamNameAction => {
  return { type: SET_TEAM_NAME, payload: name };
};

export const setTeamHasPaid = (hasPaid: boolean): SetTeamHasPaidAction => {
  return { type: SET_TEAM_HAS_PAID, payload: hasPaid };
};

// quests

export type SetQuestsAction = {
  type: typeof SET_QUESTS;
  payload: Quest[];
};

export type SetQuestDoneAction = {
  type: typeof SET_QUEST_DONE;
  payload: {
    questId: QuestIdType;
    solvedOn: SolvedOn;
  };
};

export type SetQuestsDoneAction = {
  type: typeof SET_QUESTS_DONE;
  payload: QuestsDone;
};

export type QuestsAction = SetQuestsAction | SetQuestDoneAction | SetQuestsDoneAction;

export const setQuests = (quests: Quest[]): SetQuestsAction => {
  return { type: SET_QUESTS, payload: quests };
};

export const setQuestDone = (questId: QuestIdType, solvedOn: SolvedOn): SetQuestDoneAction => {
  return {
    type: SET_QUEST_DONE,
    payload: {
      questId,
      solvedOn,
    },
  };
};

export const setQuestsDone = (questsDone: QuestsDone): SetQuestsDoneAction => {
  return {
    type: SET_QUESTS_DONE,
    payload: questsDone,
  };
};


// riddles

export type SetRiddlesAction = {
  type: typeof SET_RIDDLES;
  payload: Riddle[];
};

export type SetRiddleSolvedAction = {
  type: typeof SET_RIDDLE_SOLVED;
  payload: {
    riddleId: RiddleIdType;
    solvedOn: SolvedOn;
  };
};

export type SetRiddlesSolvedAction = {
  type: typeof SET_RIDDLES_SOLVED;
  payload: SolvedRiddles;
};

export type SetRiddleAction = {
  type: typeof SET_RIDDLE;
  payload: Riddle;
}

export type RiddlesAction = SetRiddlesAction | SetRiddleSolvedAction | SetRiddlesSolvedAction | SetRiddleAction;

export const setRiddles = (riddles: Riddle[]): SetRiddlesAction => {
  return { type: SET_RIDDLES, payload: riddles };
};

export const setRiddle = (riddle: Riddle): SetRiddleAction => {
  return { type: SET_RIDDLE, payload: riddle };
};

export const setRiddleSolved = (riddleId: RiddleIdType, solvedOn: SolvedOn): SetRiddleSolvedAction => {
  return {
    type: SET_RIDDLE_SOLVED,
    payload: {
      riddleId,
      solvedOn,
    },
  };
};

export const setRiddlesSolved = (solvedRiddles: SolvedRiddles): SetRiddlesSolvedAction => {
  return {
    type: SET_RIDDLES_SOLVED,
    payload: solvedRiddles,
  };
};
