import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './ui/components/PrivateRoute';
import Login from './ui/pages/Login';
import Register from './ui/pages/Register';
import Home from './ui/pages/Home';
import Task from './ui/pages/Task';
import NotFound from './ui/pages/NotFound';
import Payment from './ui/pages/Payment';
import ForgottenPassword from './ui/pages/ForgottenPassword';
import PasswordReset from './ui/pages/PasswordReset';
import AdminSignin from './ui/pages/AdminSignin';
import AdminHome from './ui/pages/AdminHome';
import Rules from './ui/pages/Rules';
import QuestMap from './ui/pages/QuestMap';

const App = (): React.ReactElement => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/signin' component={Login} />
        <Route exact path='/signup' component={Register} />
        <Route exact path='/forgotten-password' component={ForgottenPassword} />
        <Route exact path='/password-reset/:key' component={PasswordReset} />
        <Route exact path='/admin/signin' component={AdminSignin} />
        <Route exact path='/rules' component={Rules} />
        <PrivateRoute exact path='/' component={Home} />
        <PrivateRoute exact path='/admin' component={AdminHome} />
        <PrivateRoute exact path='/tasks/:type/:id/:step?' component={Task} />
        <PrivateRoute exact path='/payment' component={Payment} />
        <PrivateRoute exact path='/map' component={QuestMap} />
        <Route path='/404' component={NotFound} />
        <Redirect from='/darkvador' to='/tasks/riddles/contre-la-montre/darkvador' />
        <Redirect from='/tasks/darkvador' to='/tasks/riddles/contre-la-montre/darkvador' />
        <Redirect from='/tasks/riddles/darkvador' to='/tasks/riddles/contre-la-montre/darkvador' />
        <Redirect from='*' to='/404' />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
