import React from 'react';
import classNames from 'classnames';
import CustomTextField from './CustomTextField';

const MemberForm = ({
  memberType = 'coéquipier',
  fieldClassName = '',
  onFirstNameChange = (arg: string) => { },
  onLastNameChange = (arg: string) => { },
  onEmailChange = (arg: string) => { },
  serverError = '',
  firstNameError = '',
  lastNameError = '',
  emailError = '',
  needsEmail = false
}) => {
  return (
    <>
      <CustomTextField
        variant='outlined'
        className={classNames(fieldClassName)}
        fullWidth
        required
        label={`Nom du ${memberType}`}
        onChange={({ target: { value } }) => onFirstNameChange(value)}
        {...firstNameError && { helperText: firstNameError, error: true }}
        {...serverError && { error: true }}
      />
      <CustomTextField
        variant='outlined'
        className={classNames(fieldClassName)}
        fullWidth
        required
        label={`Prénom du ${memberType}`}
        onChange={({ target: { value } }) => onLastNameChange(value)}
        {...lastNameError && { helperText: lastNameError, error: true }}
        {...serverError && { error: true }}
      />
      {needsEmail && (
        <CustomTextField
          variant='outlined'
          className={classNames(fieldClassName)}
          fullWidth
          required
          label={`Adresse e-mail du ${memberType}`}
          onChange={({ target: { value } }) => onEmailChange(value)}
          {...emailError && { helperText: emailError, error: true }}
          {...serverError && { error: true }}
        />
      )}
    </>
  );
};

export default MemberForm;
