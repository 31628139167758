import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Typography, makeStyles } from '@material-ui/core';
import { formatDuration } from '../../helpers/common';

interface ChronometerProps {
  startDate: string;
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

const Chronometer = ({ startDate }: ChronometerProps): React.ReactElement | null => {
  const [duration, setDuration] = useState<number>();
  const countRef = useRef<NodeJS.Timeout>();
  const classes = useStyles();

  const getDuration = useCallback(() => moment.duration(moment().diff(moment(startDate))).asMilliseconds(), [startDate]);

  const getFormattedDuration = useCallback((duration: number) => formatDuration(duration), []);

  useEffect(() => {
    countRef.current = setInterval(() => {
      setDuration(getDuration());
    }, 1000);
    return () => {
      if (countRef.current) {
        clearInterval(countRef.current);
      }
    };
  }, [getDuration]);

  if (!duration) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Typography variant='body2' component='div'>Temps écoulé</Typography>
      <Typography variant='h6'>{getFormattedDuration(duration)}</Typography>
    </div>
  );
};

export default Chronometer;