import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core';
import Header from './Header';
import Footer from './Footer';

const useStyles = makeStyles(() => ({
  app: ({ noBackground }: { noBackground: boolean }) => ({
    minHeight: '100%',
    ...!noBackground && {
      backgroundColor: 'rgb(43,49,55,0.98)',
      backgroundImage: 'url(https://d2n81j17dx4v5l.cloudfront.net/background_inverted_dark_green.svg)',
      backgroundPosition: 'center',
      backgroundSize: '100% auto',
      backgroundRepeat: 'repeat-y',
    },
  }),
}));

const PublicLayout = ({ children, noBackground = false }: { children: ReactNode, noBackground?: boolean }): React.ReactElement => {
  const classes = useStyles({ noBackground });
  return (
    <div className={classes.app}>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default PublicLayout;