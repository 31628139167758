import { parseOrThrowError } from '../helpers/request';
import { getToken } from '../helpers/auth';
import { SolvedRiddles } from '../types/riddles';
import { QuestsDone } from '../types/quests';

const API_URI = process.env.REACT_APP_API_URI;

interface FetchMyTeamResponse {
  name: string;
  hasPaid: boolean;
  solvedRiddles: SolvedRiddles;
  questsDone: QuestsDone;
}

export const fetchMyTeam = async (): Promise<FetchMyTeamResponse> => {
  const token = getToken();
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);

  const response = await fetch(`${API_URI}/team/mine`, {
    method: 'GET',
    headers,
    cache: 'no-store',
  });
  return parseOrThrowError<FetchMyTeamResponse>(response);
};

export interface FetchTeamStatsResponse {
  answersSubmissionCount: number;
  solvedRiddles: SolvedRiddles;
  questsDone: SolvedRiddles;
}

export const fetchTeamStats = async (): Promise<FetchTeamStatsResponse> => {
  const token = getToken();
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);

  const response = await fetch(`${API_URI}/team/stats`, {
    method: 'GET',
    headers,
    cache: 'no-store',
  });

  return parseOrThrowError<FetchTeamStatsResponse>(response);
};

interface CreatePaymentIntentResponse {
  clientSecret: string;
}

export const createPaymentIntent = async (): Promise<CreatePaymentIntentResponse> => {
  const token = getToken();
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);

  const response = await fetch(`${API_URI}/team/create-payment-intent`, {
    method: 'POST',
    headers,
    cache: 'no-store',
  });
  return parseOrThrowError<CreatePaymentIntentResponse>(response);
};

interface PaymentResultResponse {
  id: string;
}

export const sendPaymentResult = async (paymentMethod: string, status: string, id: string): Promise<PaymentResultResponse> => {
  const token = getToken();
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);
  headers.append('Content-Type', 'application/json');

  const response = await fetch(`${API_URI}/team/payment-result`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ paymentMethod, status, id }),
    cache: 'no-store',
  });
  return parseOrThrowError<PaymentResultResponse>(response);
};
