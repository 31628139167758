import moment from 'moment';

export const sleep = async (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));

export const range = (size: number): ReadonlyArray<number> => (
  Array.from(Array(size)).map((_, i) => i)
);

export const getFormattedDateTime = (solvedOn: string): string => (
  moment(solvedOn).locale('fr').format('D MMMM à H:mm')
);

const toPlural = (number: number, string: string) => {
  if (string.endsWith('s')) return string;
  const plural = number > 1 ? 's' : ''
  return `${string}${plural}`
}

export const formatDuration = (durationInMS: number) => {
  const momentDuration = moment.duration(durationInMS)
  const months = momentDuration.months()
  const weeks = momentDuration.weeks()
  const days = momentDuration.days()
  const hours = momentDuration.hours()
  const minutes = momentDuration.minutes()
  const seconds = momentDuration.seconds()
  const parts = [
    months ? `${months} ${toPlural(months, 'mois')}` : null,
    months || weeks ? `${weeks} ${toPlural(weeks, 'semaine')}` : null,
    months || weeks || days ? `${days} ${toPlural(days, 'jour')}` : null,
    months || weeks || days || hours ? `${hours} ${toPlural(hours, 'heure')}` : null,
    months || weeks || days || hours || minutes ? `${minutes} ${toPlural(minutes, 'minute')}` : null,
    months || weeks || days || hours || minutes || seconds ? `${seconds} ${toPlural(seconds, 'seconde')}` : null,
  ].filter(Boolean)
  return `${parts.join(' ')}`
}