import classNames from 'classnames';
import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getAvailability, sendPasswordReinitialisationEmail } from '../../services/auth';
import { themeObject, bigGreenButton, form } from '../../helpers/style';
import { validateRequiredField } from '../../helpers/validation';
import CustomTextField from './CustomTextField';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  form,
  formElement: {
    margin: '1rem 0',
  },
  bigGreenButton,
}));

const theme = createMuiTheme({ ...themeObject, palette: { type: 'light' }, });

const ForgottenPasswordForm = (): React.ReactElement => {
  const classes = useStyles();

  const [login, setLogin] = useState('');
  const [serverError, setServerError] = useState('');
  const [loginError, setLoginError] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);

  const sendPasswordResetEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    if (sendingEmail || emailSent) return;
    const hasErrors = !validateRequiredField(login);
    if (hasErrors) {
      setLoginError('Veuillez renseigner le login de votre équipe');
      return;
    }
    setSendingEmail(true);
    const { isLoginAvailable } = await getAvailability(login || '');
    if (isLoginAvailable) {
      setLoginError('Ce login ne correspond à aucune équipe');
      setSendingEmail(false);
      return;
    }
    try {
      await sendPasswordReinitialisationEmail(login);
      setEmailSent(true);
      setSendingEmail(false);
    } catch (err) {
      setServerError(err.message);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Paper elevation={0} className={classes.form}>
        <form onSubmit={sendPasswordResetEmail}>
          <Box>
            <CustomTextField
              variant='outlined'
              required
              fullWidth
              disabled={sendingEmail || emailSent}
              className={classes.formElement}
              label='Login de votre équipe'
              onChange={({ target: { value } }) => {
                setLogin(value);
                if (loginError) { setLoginError('') }
              }}
              {...serverError && { error: true }}
              {...loginError && { error: true, helperText: loginError }}
            />
          </Box>
          <Button
            fullWidth
            type='submit'
            variant='outlined'
            size='large'
            className={classNames(classes.bigGreenButton, classes.formElement)}
          >
            {sendingEmail ? <CircularProgress color='inherit' /> : emailSent ? 'E-mail envoyé ! Si vous ne recevez pas l\'email assurez-vous que le login est bon' : `Recevoir l'e-mail`}
          </Button>
        </form>
      </Paper>
    </ThemeProvider >
  );
};

export default ForgottenPasswordForm;
